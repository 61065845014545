import moment from 'moment'
import { processReservations } from './helpers/processReservations'
import { createMCSelector } from '../../../../../Modules/helpers/createMultiCacheSelector'
import { createSelector } from 'reselect'
import { settingsSelectors } from '../../../../../Modules/settings'
import { currentDateSelectors } from '../../../../../Modules/current-date'
import { roomsSelectors } from '../../../../../Modules/rooms'

const everything = state => state.grid

export const reservations = state => everything(state).reservations

export const breakfastsSelector = state => state.breakfastsBooked
export const breakfastsInitial = state => breakfastsSelector(state).items

export const breakfasts = createSelector(breakfastsInitial, items =>
  items.reduce((acc, item) => {
    acc[item.booking_number] = items?.filter(it => it.booking_number === item.booking_number).map(it => ({
      ...it,
      quantity: it.qty
    }))
    return acc
  }, {})
)

export const reservationsByPk = createSelector(reservations, reservations =>
  reservations.reduce((acc, reservation) => {
    acc[reservation.pk] = reservation
    return acc
  }, {})
)
export const reservationsByBookingNumber = createSelector(
  reservations,
  reservations =>
    reservations.reduce((acc, reservation) => {
      acc[reservation.booking_number] = reservation
      return acc
    }, {})
)

export const notes = state => everything(state).notes
export const groupReservations = state => everything(state).groupReservations

export const processedData = createMCSelector(
  reservations,
  notes,
  groupReservations,
  breakfasts,
  (reservations, notes, groupReservations, breakfasts) => {
    return processReservations(reservations, notes, groupReservations, breakfasts)
  }
)

export const processedReservations = createSelector(
  processedData,
  processedData => processedData.reservations
)

export const model = state => processedData(state).model

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed
export const error = state => everything(state).error

export const loadState = createSelector(
  isLoading,
  isLoaded,
  isFailed,
  error,
  (isLoading, isLoaded, isFailed, error) => ({
    isLoading,
    isLoaded,
    isFailed,
    error,
  })
)

export const usedDate = createSelector(
  currentDateSelectors.today,
  settingsSelectors.gridDate,
  (currentDate, gridDate) => {
    if (gridDate) return gridDate
    return currentDate
  }
)

export const dates = createSelector(
  settingsSelectors.daysInPast,
  settingsSelectors.daysInFuture,
  usedDate,
  (daysInPast, daysInFuture, usedGridDate) => {
    const result = []

    const date = moment(usedGridDate).subtract(daysInPast, 'days')

    for (let i = 0; i < daysInPast + 1 + daysInFuture; i++) {
      result.push(date.format('YYYY-MM-DD'))
      date.add(1, 'day')
    }

    return result
  }
)

export const mapRowIndexToRoom = createSelector(roomsSelectors.items, rooms =>
  rooms.reduce((acc, room, index) => {
    acc[index] = room
    return acc
  }, {})
)

export const mapColumnIndexToDate = createSelector(dates, dates =>
  dates.reduce((acc, date, index) => {
    acc[index] = date
    return acc
  }, {})
)

export const gridItemData = createSelector(
  model,
  processedReservations,
  mapRowIndexToRoom,
  mapColumnIndexToDate,
  usedDate,
  settingsSelectors.isRoomStatusesEnabled,
  (model, reservations, mapRowIndexToRoom, mapColumnIndexToDate, usedDate, isRoomStatusesEnabled) => ({
    model,
    reservations,
    mapRowIndexToRoom,
    mapColumnIndexToDate,
    usedDate,
    isRoomStatusesEnabled
  })
)
