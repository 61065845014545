export const LOAD_BY_TYPE_DATA_REQUEST =
  'ghotel/free-rooms/LOAD_BY_TYPE_DATA_REQUEST'
export const LOAD_BY_TYPE_DATA_SUCCESS =
  'ghotel/free-rooms/LOAD_BY_TYPE_DATA_SUCCESS'
export const LOAD_BY_TYPE_DATA_FAILURE =
  'ghotel/free-rooms/LOAD_BY_TYPE_DATA_FAILURE'

export const LOAD_FOR_DATE_DATA_REQUEST =
  'ghotel/free-rooms/LOAD_FOR_DATE_DATA_REQUEST'
export const LOAD_FOR_DATE_DATA_SUCCESS =
  'ghotel/free-rooms/LOAD_FOR_DATE_DATA_SUCCESS'
export const LOAD_FOR_DATE_DATA_FAILURE =
  'ghotel/free-rooms/LOAD_FOR_DATE_DATA_FAILURE'

export const RESET = 'ghotel/free-rooms/RESET'
