import * as types from './types'

const initialState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_CANCELED_WARNINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case types.LOAD_CANCELED_WARNINGS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        isLoaded: true,
      }

    case types.LOAD_CANCELED_WARNINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      }

    case types.UPDATE_ITEMS_LIST:
      return {
        ...state,
        data: state.data.map((it) => {
          if (it.pk === payload.item.pk)
            return payload.item
          return it
        }),
        isLoading: false,
        isLoaded: true,
      }
    case types.RESET:
      return initialState

    default:
      return state
  }
}
