import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { ccAPI } from '../../../../Modules/api/ccAPI'
import { Notifications } from '../../../../Modules/Notifications'
import { Button } from '../../../buttons'
import { useActions } from '../../../common/hooks/use-actions'
import { gridActions } from '../../reducers/grid'
import { useSelector } from 'react-redux'
import { sessionSelectors } from '../../../../Modules/session'
import Logs from '../../../../Modules/Logs'
import { callModal, modalTemplates } from '../../../dynamic-modal'
import { modalActions } from '../../../dynamic-modal/templates/confirm'
import { canceledWarningsActions } from '../../reducers/canceled-warnings'


const Block = styled.div`
  padding: 30px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    color: #da4e4e;
    font-size: 14px;
    margin: 0;
    text-align: center;
  }
`

const Link = styled.button`
  padding: 0;
  border: none;
  border-bottom: 1px dashed transparent;
  color: #2b62ff;
  background-color: transparent;
  outline: none;
`

export const Content = ( {items, handelCheck, header, text}) => {
  const { loadData: gridLoad } = useActions(gridActions)
  const { updateItemsList } = useActions(canceledWarningsActions)
  const adminName = useSelector(sessionSelectors.adminName)

  let history = useHistory();

  if (items.length) {
    const goTo = pk => history.push(`/reservation?pk=${pk}`)

    const setMark = async (booking) => {

      const { action } = await callModal(
        modalTemplates.confirm({
          text: 'Бронь исчезнет из списка требующих внимания. Вы уверены, что хотите пометить бронь обработанной? ',
          buttons: ['Нет', 'Да'],
        })
      )

      if (action === modalActions.confirm) {
        let { pk, booking_number } = booking

        const data = await handelCheck (booking)
        return await ccAPI.saveReservationCall(data)
          .then((res) => updateItemsList(res))
          .then(() => gridLoad())
          .then(_ => {
            Logs.logSubmit(pk, `Пометить обработанной ${booking_number} (${adminName})`)
            Notifications.success('Успех')
            return true
          })
          .catch(() => {
              Notifications.failure('Что-то пошло не так')
              return false
            }
          )
      }
    }

    return (

      <Block>
        {header}
        {text}
        <Table
          responsive
          className="table-hover condensed"
          style={{ fontSize: 12, width: '100%' }}
        >
          <thead>
          <tr>
            <th>Комната</th>
            <th>Гость</th>
            <th />
          </tr>
          </thead>
          <tbody>{
            items.map((it, index) => (
              <Row item={it}
                   key={`${it.booking_number}_${index}`}
                   goTo={goTo}
                   setMark={setMark}
              />
            ))}
          </tbody>
        </Table>
      </Block>
    )
  } else return ''
}

const Row = ({ item, goTo, setMark }) => {
  const { pk, room_names, guest_name } = item
  return (
    <tr>
      <td>{room_names}</td>
      <td>{guest_name}</td>
      <td>
        <Link
          onClick={() => goTo(pk)}
        >
          Перейти в бронь
        </Link>
      </td>
      <td>
        <Button
          size="xs"
          type="warning"
          onClick={() => setMark(item)}
        >
          Пометить обработанной
        </Button>
      </td>
    </tr>
  )
}


