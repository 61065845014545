import { createSelector } from 'reselect'
import { warningsKind } from '../../../../Constants/warning-types'

export const everything = state => state.canceledWarnings

export const items = (state) => everything(state).data

export const isLoading = (state) => everything(state).isLoading

export const isLoaded = (state) => everything(state).isLoaded

export const isFailed = (state) => everything(state).isFailed

export const reservationWarnings = createSelector(items, items =>
  items.filter(({ warnings }) => warnings.find(({
                                                  kind,
                                                  action_performed
                                                }) => !action_performed && [warningsKind.canceled_and_ready, warningsKind.group_part_canceled].includes(kind)))
)

export const breakfastWarnings = createSelector(items, items =>
  items.filter(({ warnings }) => warnings.find(({
                                                  kind,
                                                  action_performed
                                                }) => !action_performed && kind === warningsKind.prepaid_breakfasts_left))
)
