import {ModalButton, ModalControls, ModalHR, ModalTitle} from '../../../../common/ModalParts'
import React, {useEffect, useState} from 'react'
import Modal from 'react-modal'
import {ModalBody} from 'react-bootstrap'
import {Select} from '../../../../form-stuff'
import {ccAPI} from '../../../../../Modules/api/ccAPI'
import {useSelector} from 'react-redux'
import {sessionSelectors} from '../../../../../Modules/session'
import {Notifications} from '../../../../../Modules/Notifications'
import PureDatePicker from 'react-datepicker'
import {room_statuses, room_statuses_values} from './room-statuses'
import moment from 'moment/moment'
import generateGUID from '../../../../../Modules/CheckPrintController/generateGUID'
import styled from 'styled-components'
import {FaPlus, FaTimes} from 'react-icons/fa'
import {Button} from '../../../../buttons'
import {callAlert, callPrompt} from '../../../../dynamic-modal'
import {useActions} from '../../../../common/hooks/use-actions'
import {roomsActions} from '../../../../../Modules/rooms'
import uuid from 'uuid/v4'

const Flex = styled.div`
    display: flex;
    margin-bottom: 10px;
    gap: 10px;


    > div:first-child {
        width: 50%;

        b {
            display: block;
        }
    }

    button {
        margin: 0;

        svg {
            height: 100%;
        }
    }
`

const be_format = 'YYYY-MM-DD'
const fe_format = 'DD.MM.YYYY'

export const ChangeRoomStatusModal = ({isOpen, close, room}) => {
    const {room_id, status: currentStatus, repair_schedule} = room
    const lcode = useSelector(sessionSelectors.lcode)
    const {load: loadRooms} = useActions(roomsActions)
    const [status, setStatus] = useState(currentStatus)
    const [dates, setDates] = useState(null)
    const [notAccepted, setNotAccepted] = useState(true)
    const [error, setError] = useState(true)

    useEffect(() => {
        const data = repair_schedule.reduce((a, b) => {
            a[generateGUID()] = b
            return a
        }, repair_schedule.length ? {} : {
            [generateGUID()]: {
                start_date: null,
                end_date: null
            }
        })
        setDates(data)
    }, [repair_schedule])

    useEffect(() => {
        setError(null)
    }, [status]);

    const onChange = (uid, dates) => {
        if (notAccepted) {
            callPrompt({
                title: 'Проверка',
                text: [
                    'Введите пароль для смены дат ремонта',
                    'Запросите его у тех.директора.',
                ],
            }).then(async password => {
                if (password == null) {
                    return
                }
                await ccAPI
                    .getRepairDatePassword(password)
                    .then(() => {
                        setDates((prev) => {
                            prev[uid] = dates
                            return prev
                        })
                        setNotAccepted(false)
                    })
                    .catch(() => {
                        callAlert({
                            title: 'Ошибка',
                            text: 'Неправильный пароль',
                        })
                    })
            })
        } else setDates((prev) => {
            prev[uid] = dates
            return prev
        })
    };

    const add = () => {
        const obj = {...dates}
        obj[generateGUID()] = {
            start_date: null,
            end_date: null
        }
        setDates(obj)
    }

    const remove = (uid) => {
        const res = Object.keys(dates).filter(objKey =>
            objKey !== uid).reduce((a, b) => {
                a[b] = dates[b];
                return a;
            }, {}
        )
        if (!res.length) {
            res[generateGUID()] = {
                start_date: null,
                end_date: null
            }
        }
        setDates(res)
    }

    const save = () => {
        const data = {lcode, room_id, status}
        if (status === room_statuses.repair.title) {
            data.dates = Object.values(dates)
        }
        ccAPI.changeRoomStatus(data).then(_ => {
            loadRooms()
            close()
            setError(null)
            Notifications.success('Успех')
        }).catch(({error}) => {
            setError(error)
            Notifications.failure('Что-то пошло не так')
        })
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalTitle>Статус комнаты</ModalTitle>
            <ModalHR/>
            <ModalBody>
                <div>
                    <Select
                        label="Статус комнаты"
                        options={room_statuses_values}
                        value={status}
                        onChange={setStatus}
                    />
                    {error && <div style={{color: "#d61b3e"}}>{error}</div>}
                    {repair_schedule.length && status !== room_statuses.repair.title ? <>
                        <Flex>
                            В данном номере запланирован ремонт:
                            <div>
                                {repair_schedule.map(({start_date, end_date}) =>
                                    <b key={uuid()}>{moment(start_date).format(fe_format)} - {moment(end_date).format(fe_format)}</b>
                                )}
                            </div>
                        </Flex>
                        Для редактирования расписания ремонта выберите статус "Ремонт"
                    </> : <></>}
                    {dates && status === room_statuses.repair.title &&
                        <>
                            Укажите даты начала и окончания ремонта.
                            {Object.entries(dates).map(([uid, {start_date, end_date}], index) =>
                                <DatePicker key={uid}
                                            start={start_date}
                                            end={end_date}
                                            setDates={dates => onChange(uid, dates)}
                                            remove={() => remove(uid)}
                                            add={add}
                                            first={index === 0}
                                />
                            )}
                        </>
                    }
                </div>
            </ModalBody>
            <ModalControls>
                <ModalButton
                    bsStyle="success"
                    onClick={save}
                >
                    Сохранить
                </ModalButton>
                <ModalButton
                    bsStyle="danger"
                    onClick={close}
                >
                    Отмена
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const DatePicker = ({start, end, setDates, remove, add, first}) => {
    const [startDate, setStartDate] = useState(start ? new Date(start) : null);
    const [endDate, setEndDate] = useState(end ? new Date(end) : null);
    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start);
        setEndDate(end);
        if (start && end)
            setDates({
                start_date: moment(start).format(be_format),
                end_date: moment(end).format(be_format)
            })
    }
    return (
        <Flex>
            <PureDatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd.MM.yyyy"
                selectsRange
            />
            {start && end && <Button
                size="small"
                type="danger"
                onClick={remove}
            >
                <FaTimes/>
            </Button>}
            {first && <Button
                size="small"
                onClick={add}
            >
                <FaPlus/>
            </Button>
            }
        </Flex>
    )
}
