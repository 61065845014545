import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { currentDateSelectors } from '../../../../Modules/current-date'
import { day } from '../../../../Modules/formatters/date'
import { noDatesOption } from '../../constants/default-values'

function getDefaultValue({ availableDates, today }) {
  if (availableDates.lenght === 0) {
    return noDatesOption.value
  }

  if (availableDates.includes(today)) {
    return today
  }

  return availableDates[0]
}

export function useDateSelectData(availableDates) {
  const today = useSelector(currentDateSelectors.today)

  const options = useMemo(() => {
    return availableDates.map(date => ({
      label: day(date),
      value: date,
    }))
  }, [availableDates])

  const defaultOption = availableDates.length > 0 ? null : noDatesOption

  const defaultValue = getDefaultValue({ availableDates, today })

  const [date, setDate] = useState(defaultValue)

  useEffect(() => {
    if (date && availableDates.includes(date)) return
    setDate(defaultValue)
    // eslint-disable-next-line
  }, [defaultValue])

  return {
    date,
    setDate,
    resetDate: () => setDate(defaultValue),
    dateOptions: options,
    dateDefaultOption: defaultOption,
    dateDefaultValue: defaultValue,
  }
}
