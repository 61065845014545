import moment from 'moment'
import * as types from './types'
import { setNewWubookLoaded } from '../../../../Modules/helpers/wasNewWubookLoadedRecently'
import { Notifications } from '../../../../Modules/Notifications'
import { callModal, modalTemplates } from '../../../dynamic-modal'
import {
  cancelReservationCall,
  checkNewWubookCall,
  getLocalPrepayeds,
  getWubookReservationsCallByDate
} from "../../../../Modules/api/ccAPI/requests"
import { page_size } from '../../../../Constants/others'

const loadDataRequest = () => ({
  type: types.LOAD_DATA_REQUEST,
})

const loadDataSuccess = ({ wubook, prepayeds, wubookCount }) => ({
  type: types.LOAD_DATA_SUCCESS,
  payload: {
    wubook,
    prepayeds,
    wubookCount
  },
})

const loadDataFailure = () => ({
  type: types.LOAD_DATA_FAILURE,
})

export const loadData = (page = 1) => async (dispatch) => {
  dispatch(loadDataRequest())

  try {
    const now = moment()
    const today = now.format('YYYY-MM-DD')

    const promises = []

    promises.push(getLocalPrepayeds())

    promises.push(getWubookReservationsCallByDate({ date: today, params: { page, ordering: '-start', page_size } }))

    const [prepayeds = [], { count, results } = {}] = await Promise.all(promises)
    const wubook = results.reduce((acc, wb) => acc.concat(wb), [])
    dispatch(
      loadDataSuccess({
        wubook,
        prepayeds,
        wubookCount: count,
      })
    )
  } catch (err) {
    dispatch(loadDataFailure())
  }
}

export const refreshData = () => dispatch => {
  dispatch(loadData())
}

const updateDataRequest = () => ({
  type: types.UPDATE_DATA_REQUEST,
})

const updateDataSuccess = () => ({
  type: types.UPDATE_DATA_SUCCESS,
})

const updateDataFailure = () => ({
  type: types.UPDATE_DATA_FAILURE,
})

export const updateData = () => async (dispatch, getState) => {
  const { session, settings } = getState()
  const { isManualNewBookingsFetchEnabled } = settings

  dispatch(updateDataRequest())
  setTimeout(async () => {
    try {
      if (isManualNewBookingsFetchEnabled) {
        await checkNewWubookCall(session.lcode)
      }

      dispatch(updateDataSuccess())
      dispatch(refreshData())

      setNewWubookLoaded()

      Notifications.success(
        'Синхронизация с сервером произведена, загружаем актуальные данные..'
      )
    } catch (err) {
      dispatch(updateDataFailure())

      Notifications.failure('Произошла ошибка при загрузке заездов')
    }
  }, 4000)
}

const cancelReservationRequest = pk => ({
  type: types.CANCEL_RESERVATION_REQUEST,
  payload: {
    pk,
  },
})

const cancelReservationSuccess = pk => ({
  type: types.CANCEL_RESERVATION_SUCCESS,
  payload: {
    pk,
  },
})

const cancelReservationFailure = pk => ({
  type: types.CANCEL_RESERVATION_FAILURE,
  payload: {
    pk,
  },
})

const cancelReservationCanceled = pk => ({
  type: types.CANCEL_RESERVATION_CANCELED,
  payload: {
    pk,
  },
})

export const cancelReservation = ({ pk, booking_number, guest_name } ) => async dispatch => {
  dispatch(cancelReservationRequest(pk))

  try {

    const { action } = await callModal(
      modalTemplates.confirm({
        text: [
          `Вы собираетесь отменить бронь с номером ${booking_number} ` +
          `и гостем ${guest_name}.`,
          'После этого заезд по ней будет невозможен.',
          'Продолжить?',
        ],
        buttons: ['Отмена', 'Да'],
      })
    )

    if (action === modalTemplates.confirm.actions.close) {
      dispatch(cancelReservationCanceled(pk))
      return
    }

    await cancelReservationCall(pk)

    dispatch(cancelReservationSuccess(pk))
    dispatch(refreshData())

    callModal(
      modalTemplates.alert({
        title: 'Успех',
        text: `Бронь с номером ${booking_number} была успешно отменена.`,
      })
    )
  } catch ({ error }) {
    dispatch(cancelReservationFailure(pk))

    callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: `Не удалось отменить бронь (${error}).`,
      })
    )
  }
}

export const reset = () => ({
  type: types.RESET,
})
