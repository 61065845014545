import React from 'react'
import styled from 'styled-components'
import { rub } from '../../../../../Modules/formatters/currency'
import { useTooltipRef } from '../tooltip/use-tooltip-ref'
import { useTooltipState } from '../tooltip/use-tooltip-state'

const TooltipBlock = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  z-index: 200;
  width: 240px;
  padding: 15px;
  text-align: left;
  pointer-events: none;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  transform: translate(calc(-100% - 10px), calc(-100% + 30px));
`

const Field = styled.div`
  margin-top: 4px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

const TooltipValueTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #444444;
  margin: 4px 0 6px 0;
`

const TooltipValueText = styled.p`
  font-size: 15px;
  color: #333333;

  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Content = ({ reservationInfo }) => {
  if (!reservationInfo) return null

  return (
    <>
      <Field>
        <TooltipValueTitle>Номер брони</TooltipValueTitle>
        <TooltipValueText>{reservationInfo.bookingNumber}</TooltipValueText>
      </Field>
      {reservationInfo.clientName && (
        <Field>
          <TooltipValueTitle>Фамилия клиента</TooltipValueTitle>
          <TooltipValueText>{reservationInfo.clientName}</TooltipValueText>
        </Field>
      )}
      {reservationInfo.clientPhone && (
        <Field>
          <TooltipValueTitle>Телефон клиента</TooltipValueTitle>
          <TooltipValueText>{reservationInfo.clientPhone}</TooltipValueText>
        </Field>
      )}
      {reservationInfo.clientNote && (
        <Field>
          <TooltipValueTitle>Примечание</TooltipValueTitle>
          <TooltipValueText>{reservationInfo.clientNote}</TooltipValueText>
        </Field>
      )}
      {reservationInfo.serverNotes && (
        <Field>
          <TooltipValueTitle>Серверные примечания</TooltipValueTitle>
          {reservationInfo.serverNotes.map(note => {
            return <TooltipValueText>{note.note}</TooltipValueText>
          })}
        </Field>
      )}
      {reservationInfo.totalSumm && (
        <Field>
          <TooltipValueTitle>Общая сумма</TooltipValueTitle>
          <TooltipValueText>{rub(reservationInfo.totalSumm)}</TooltipValueText>
        </Field>
      )}
      {reservationInfo.payedSumm && (
        <Field>
          <TooltipValueTitle>Оплаченная сумма</TooltipValueTitle>
          <TooltipValueText>{rub(reservationInfo.payedSumm)}</TooltipValueText>
        </Field>
      )}
    </>
  )
}

export const GridTooltip = () => {
  const { isVisible, reservationInfo, mouseX, mouseY } = useTooltipState()
  const ref = useTooltipRef()

  return (
    <TooltipBlock
      ref={ref}
      isVisible={isVisible}
      mouseX={mouseX}
      mouseY={mouseY}
    >
      <Content reservationInfo={reservationInfo} />
    </TooltipBlock>
  )
}
