import { TooltipState } from './tooltip-state'
import store from '../../../../../store'
import { gridSelectors } from '../../../reducers/grid'
import { extractTooltipData } from '../helpers/extractTooltipData'
import { settingsSelectors } from '../../../../../Modules/settings'

function findCellNode(node) {
  if (node == null) return null

  const isCell = node.classList.contains('tbody-cell')

  if (isCell) return node

  return findCellNode(node.parentElement)
}

export function updateTooltipPosition({ event, tooltipRef }) {
  const tooltipNode = tooltipRef.current

  if (tooltipNode) {
    tooltipNode.style.left = event.clientX + 'px'
    tooltipNode.style.top = event.clientY + 'px'
  }
}

export function updateTooltipState({ eventTarget }) {
  const cellNode = findCellNode(eventTarget)

  const isMouseOnCell = Boolean(cellNode)

  if (!isMouseOnCell) {
    TooltipState.updateState({
      isVisible: false,
      reservationInfo: null,
    })

    return
  }

  const isCellEmpty = cellNode.dataset.isEmpty === 'true'

  if (isCellEmpty) {
    TooltipState.updateState({
      isVisible: false,
      reservationInfo: null,
    })

    return
  }

  const reservationInfo = JSON.parse(cellNode.dataset.reservationInfo)

  const currentState = TooltipState.getState()

  if (
    currentState.isVisible &&
    reservationInfo.pk === currentState.reservationInfo.pk
  ) {
    // state is actual
    return
  }

  TooltipState.updateState({
    isVisible: true,
    reservationInfo,
  })
}

const mapSizeToCellSize = {
  100: 50,
  80: 44,
  60: 38,
  40: 27,
}

export function handleWheel({ event, cellsContainerRef, outerContainerRef }) {
  const { clientX, clientY } = event

  const callback = () => {
    if (!cellsContainerRef.current) return
    if (!outerContainerRef.current) return

    const { offsetLeft, offsetTop } = cellsContainerRef.current
    const { scrollLeft, scrollTop } = outerContainerRef.current

    const cellRelativeCoords = {
      x: scrollLeft + clientX - offsetLeft,
      y: scrollTop + clientY - offsetTop,
    }

    const state = store.getState()

    const processedReservations = gridSelectors.processedReservations(state)
    const model = gridSelectors.model(state)
    const mapColumnIndexToDate = gridSelectors.mapColumnIndexToDate(state)
    const mapRowIndexToRoom = gridSelectors.mapRowIndexToRoom(state)
    const size = settingsSelectors.gridScale(state)

    const cellSize = mapSizeToCellSize[size]

    const columnIndex = Math.floor(cellRelativeCoords.x / cellSize)
    const rowIndex = Math.floor(cellRelativeCoords.y / cellSize)

    const date = mapColumnIndexToDate[columnIndex]
    const room = mapRowIndexToRoom[rowIndex]

    const cellData = model?.[room.room_id]?.[date]

    if (!cellData) {
      TooltipState.reset()
      return
    }

    const reservationData = processedReservations[cellData.reservation]
    const tooltipData = extractTooltipData({ reservationData })

    TooltipState.updateState({
      isVisible: true,
      reservationInfo: tooltipData,
    })
  }

  // without timeout there the risk of receiving an element that was on these coords previously
  setTimeout(callback, 100)
}

export function resetTooltip() {
  TooltipState.reset()
}
