import { connect } from 'react-redux'
import Login from '../Components/Login/Login'

import { bindActionCreators } from 'redux';
import { hotelsActions, hotelsSelectors } from '../Modules/hotels';
import { sessionActions } from '../Modules/session';
import { settingsActions } from '../Modules/settings'
import { roomsActions } from '../Modules/rooms'

const mapStateToProps = state => ({
  isAuthorized: state.session.isAuthorized,
  lcode: state.session.lcode,

  isLoading: state.session.isLoading,
  error: state.session.error,
  lcodes: hotelsSelectors.hotelOptions(state),
  hotelsMappings: hotelsSelectors.hotelsMappings(state),
})

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  hotelsActions: bindActionCreators(hotelsActions, dispatch),

  loadData: async () => {
    await Promise.all([
      dispatch(settingsActions.loadSettings()),
      dispatch(roomsActions.load()),
    ])

  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
