export const defaultDateOption = {
  label: 'Выберите дату',
  value: '',
}

export const noDatesOption = {
  label: 'Нет доступных дат',
  value: '',
}

export const defaultServiceNameOption = {
  label: 'Выберите тип услуги',
  value: '',
}

export const defaultQuantityOption = {
  label: 'Выберите количество',
  value: '',
}

export const defaultPaymentTypeOption = {
  label: 'Выберите метод оплаты',
  value: '',
}
