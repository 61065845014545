import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import Modal from 'react-modal'

import LoginContainer from './Containers/LoginContainer'
import EditReservationContainer from './Containers/EditReservationContainer'
import NewReservationContainer from './Containers/NewReservationContainer'
import PrivateRoute from './Containers/PrivateRoute'
import NavbarContainer from './Containers/NavbarContainer'
import Search from './Components/Search/Search'
import SearchByDate from './Components/SearchByDate/SearchByDate'
import SearchByStart from './Components/SearchByStart/SearchByStart'
import NoShowSearch from './Components/NoShowSearch/NoShowSearch'
import SettingsContainer from './Containers/SettingsContainer'
import { ConnectedMainPage } from './Components/MainPage'
import TransactionsListWithConfirmAction from './Components/TransactionsList/versions/Confirm'
import TransactionsListWithCancelAction from './Components/TransactionsList/versions/Cancel'
import TransactionsListWithReturnAction from './Components/TransactionsList/versions/Return'
import ConnectedCheckPrinterCP from './Components/CheckPrinterCP'
import { ConnectedMainPage as ConnectedDayTotals } from './Components/DayTotals/features/MainPage'
import { BedclothesChangePrintPage } from './PrintPages/BedclothesChange'
import { BlankPrintPage } from './PrintPages/Blank'

import 'react-datepicker/dist/react-datepicker.css'
import { bindActionCreators } from 'redux'
import { DayTotalsPrintPage } from './PrintPages/DayTotals'
import { EnhancedAllPrepayeds } from './Components/AllPrepayeds'
import { BathroomsSchedulePrintPage } from './PrintPages/BathroomsSchedule'
import { ConnectedWubookList } from './Components/WubookList/ConnectedWubookList'
import { hotelsActions } from './Modules/hotels'
import { PayTimerFinishModal } from './Components/PayTimerFinishModal'
import { PayTimer } from './Components/PayTimer'
import { Files } from './Components/Files'
import { CurrentAdminSells } from './Components/CurrentAdminSells'
import { DynamicModalContainer } from './Components/dynamic-modal'
import { Blacklist } from './Components/Blacklist/Blacklist'
import { currentDateActions } from './Modules/current-date'
import { washingActions } from './Modules/washing'
import { roomsActions } from './Modules/rooms'
import { FreeWashing } from './Components/FreeWashing/FreeWashing'
import { Inventory } from './Components/Inventory'
import { breakfastsActions } from './Modules/breakfasts/'
import { BreakfastActions } from './Components/BreakfastActions'
import { SpecialButtons, SpecialModals } from './Components/SpecialButtons'
import { Telephony } from './Components/Telephony'
import { Trello } from './Components/Trello'
import {sessionSelectors} from "./Modules/session";
import { settingsActions } from './Modules/settings'
import { specialServiceTypesActions } from './Modules/special-service-types'
import Logs from './Modules/Logs'
import { roomsPhysicalActions } from './Modules/roomsPhysical'
import { breakfastsBookedActions } from './Modules/breakfastsBooked'
import { Cron } from './Components/CloseCashRegister/Cron'
import { RefundPrintPage } from './PrintPages/Refund'
import { MaidsContainer } from './Containers/MaidsContainer'
import { News } from './Components/SpecialButtons/News/News'
import { newsActions } from './Components/SpecialButtons/News/reducer'
import { otasListActions } from './Modules/otas-list'
import {KnowledgeBase} from "./Components/KnowledgeBase";
import {Cleaning} from "./Components/BedclothesChange/Cleaning";

export const modalDefaultStyles = {
  overlay: {
    position: 'fixed',
    zIndex: 160,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
  },
  content: {
    margin: 'auto',
    backgroundColor: '#fff',
    borderRadius: 5,
    outline: 'none',
    width: '100%',
    maxWidth: 640,
  },
}

Modal.setAppElement('#root')
Modal.defaultStyles = modalDefaultStyles

class App extends Component {
  state = {
    loaded: null,
  }
  async componentDidMount() {
    window.addEventListener('storage', async (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'reservation_changed') {
        window.location.reload();
      }
    });

    Logs.start()
    await this.props.currentDateActions.startDateTracking()
    await this.loadData()
    this.setState({ loaded: true})
  }

  async componentDidUpdate(prevProps) {
    const { admin, lcode } = prevProps
    const { admin: newAdmin, lcode: newLcode } = this.props
    if ((!admin || !lcode) && newAdmin && newLcode) {
      await this.loadData()
    }
  }

  async loadData() {
    if (this.props.admin && this.props.lcode !== null) {
      await this.props.settingsActions.loadSettings()
      await this.props.washingActions.init()
      await this.props.specialServiceTypesActions.load()
      await this.props.hotelsActions.load()
      await this.props.roomsActions.load()
      await this.props.breakfastsActions.load()
      await this.props.roomsPhysicalActions.load()
      await this.props.breakfastsBookedActions.load()
      await this.props.newsActions.load({status: "completed"})
      await this.props.newsActions.loadNewCount()
      await this.props.otasListActions.load()
    }
  }
  renderApp = () => {
    return (
      <div style={{ paddingTop: 90, height: '100%' }} className="global-page">
        {this.state.loaded && (
          <>
            <NavbarContainer />
            <Switch>
              <Route path="/login" component={LoginContainer} />
              <Route path="/maids/checkin/:uri/" component={MaidsContainer}>
              </Route>
              <PrivateRoute path="/" exact component={ConnectedMainPage} />
              <PrivateRoute
                path="/reservation"
                component={EditReservationContainer}
              />
              <PrivateRoute
                path="/new-reservation"
                component={NewReservationContainer}
              />
              <PrivateRoute path="/wubook" component={ConnectedWubookList} />
              <PrivateRoute path="/search-by-name" component={Search} />
              <PrivateRoute path="/search-by-date" component={SearchByDate} />
              <PrivateRoute path="/search-by-start" component={SearchByStart} />
              <PrivateRoute path="/no-show-search" component={NoShowSearch} />
              <PrivateRoute
                exact
                path="/check-printer-cp"
                component={ConnectedCheckPrinterCP}
              />
              <PrivateRoute
                path="/check-printer-cp/transactions/confirm"
                component={TransactionsListWithConfirmAction}
              />
              <PrivateRoute
                path="/check-printer-cp/transactions/cancel"
                component={TransactionsListWithCancelAction}
              />
              <PrivateRoute
                path="/check-printer-cp/transactions/return"
                component={TransactionsListWithReturnAction}
              />
              <PrivateRoute
                path="/all-prepayeds"
                component={EnhancedAllPrepayeds}
              />
              <PrivateRoute
                path="/bedclothes-change"
                component={Cleaning}
              />
              <PrivateRoute path="/day-totals" component={ConnectedDayTotals} />
              <PrivateRoute path="/settings" component={SettingsContainer} />
              <PrivateRoute path="/files" component={Files} />
              <PrivateRoute
                path="/my-services-sells"
                component={CurrentAdminSells}
              />
              <PrivateRoute path="/blacklist" component={Blacklist} />
              <PrivateRoute path="/free-washing" component={FreeWashing} />
              <PrivateRoute path="/local-inventory" component={Inventory} />
              <PrivateRoute
                path="/breakfast-actions"
                component={BreakfastActions}
              />
              <PrivateRoute path="/telephony" component={Telephony} />
              <PrivateRoute path="/trello" component={Trello} />
              <PrivateRoute path="/news" component={News} />
              <PrivateRoute path="/knowledge_base" component={KnowledgeBase} />
            </Switch>
            <SpecialButtons />
            <SpecialModals />
            <PayTimer />
            <PayTimerFinishModal />
            <DynamicModalContainer />
            <Cron/>
          </>
        )}
      </div>
    )
  }

  render() {
    return (
      <Switch>
        <Route
          path="/print_bedclothes_change_rooms"
          component={BedclothesChangePrintPage}
        />
        <Route
          path="/print_bathrooms_schedule"
          component={BathroomsSchedulePrintPage}
        />
        <Route path="/print_blank" component={BlankPrintPage} />
        <Route path="/print_day_totals" component={DayTotalsPrintPage} />
        <Route path="/print_refund_blank" component={RefundPrintPage} />
        <Route render={this.renderApp} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  admin: sessionSelectors.adminId(state),
  lcode: sessionSelectors.lcode(state)
})

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(settingsActions, dispatch),
  hotelsActions: bindActionCreators(hotelsActions, dispatch),
  roomsActions: bindActionCreators(roomsActions, dispatch),
  roomsPhysicalActions: bindActionCreators(roomsPhysicalActions, dispatch),
  specialServiceTypesActions: bindActionCreators(specialServiceTypesActions, dispatch),
  breakfastsActions: bindActionCreators(breakfastsActions, dispatch),
  currentDateActions: bindActionCreators(currentDateActions, dispatch),
  washingActions: bindActionCreators(washingActions, dispatch),
  breakfastsBookedActions: bindActionCreators(breakfastsBookedActions, dispatch),
  newsActions: bindActionCreators(newsActions, dispatch),
  otasListActions: bindActionCreators(otasListActions, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
