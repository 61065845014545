import moment from 'moment'
import * as types from './types'
import { getAvailableRoomsCall } from '../../../../Modules/api/ccAPI/requests'

const loadByTypeDataRequest = () => ({
  type: types.LOAD_BY_TYPE_DATA_REQUEST,
})

const loadByTypeDataSuccess = data => ({
  type: types.LOAD_BY_TYPE_DATA_SUCCESS,
  payload: {
    data,
  },
})

const loadByTypeDataFailure = () => ({
  type: types.LOAD_BY_TYPE_DATA_FAILURE,
})

export const loadByTypeData = () => async (dispatch, getState) => {
  dispatch(loadByTypeDataRequest())

  try {
    const { session } = getState()

    const today = moment().format('YYYY-MM-DD')
    const tomorrow = moment()
      .add(1, 'days')
      .format('YYYY-MM-DD')
    const data = await getAvailableRoomsCall(session.lcode, today, tomorrow)

    dispatch(loadByTypeDataSuccess(data))
  } catch (err) {
    dispatch(loadByTypeDataFailure())
  }
}

export const reset = () => ({
  type: types.RESET,
})
