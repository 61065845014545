import React from 'react'
import { Button } from 'react-bootstrap'
import {
  Segment,
  SecondaryTitle,
  Actions,
  RoomInfo,
  RoomsNumber,
  Content,
} from './common'

const FreeRoomsByTypeContent = ({
  isLoading,
  isLoaded,
  isFailed,
  calculatedData,
}) => {
  if (isLoading) return (<div>Загрузка..</div>)
  if (isFailed) return (<div>Ошибка загрузки данных</div>)
  if (!isLoaded) return (<div>Нет данных</div>)

  return calculatedData.map(room => {
    return (
      <RoomInfo key={room.name}>
        {room.name}: <RoomsNumber>{room.count}</RoomsNumber>
      </RoomInfo>
    )
  })
}

const ByTypeButton = ({ isLoading, isLoaded, isFailed, load, refresh }) => {
  if (isLoaded)
    return (
      <Button bsSize="sm" onClick={refresh} disabled={isLoading}>
        Обновить
      </Button>
    )

  if (isFailed)
    return (
      <Button bsSize="sm" onClick={load} disabled={isLoading}>
        Попробовать еще раз
      </Button>
    )

  return (
    <Button bsSize="sm" onClick={load} disabled={isLoading}>
      Загрузить данные
    </Button>
  )
}

export const ByType = ({
  isLoading,
  isLoaded,
  isFailed,
  calculatedData,
  load,
  refresh,
}) => {
  return (
    <Segment>
      <SecondaryTitle>Cегодня по типу:</SecondaryTitle>
      <Content>
        <FreeRoomsByTypeContent
          isLoading={isLoading}
          isLoaded={isLoaded}
          isFailed={isFailed}
          calculatedData={calculatedData}
        />
      </Content>
      <Actions>
        <ByTypeButton
          isLoading={isLoading}
          isLoaded={isLoaded}
          isFailed={isFailed}
          load={load}
          refresh={refresh}
        />
      </Actions>
    </Segment>
  )
}
