import React from 'react'
import Crontab from 'reactjs-crontab'
import 'reactjs-crontab/dist/index.css'
import { callModal, modalTemplates } from '../dynamic-modal'
import { useActions } from '../common/hooks/use-actions'
import { newsActions } from '../SpecialButtons/News/reducer'

export const Cron = () => {
  const {load: loadNews, loadNewCount} = useActions(newsActions)

  const getNews = () => {
    loadNews()
    loadNewCount()
  }
  const showModal = () => (
    callModal(
      modalTemplates.alert({
        title: 'Время 23:50',
        text:
          <div>Напоминаем о необходимости закрытия кассы до 23:59 и сканировании отчетов. Подробнее о
            закрытии кассы в <a
              href="https://wf-manuals.teamly.ru/auth/sign-in?redirect=%2Fspace%2Fd42eada1-6310-4324-bca8-88546d99a3bb%2Farticle%2F75679443-0827-46e0-b318-4fed2e74bea2"
              target="_blank"> базе знаний </a>.
          </div>,
      })
    )
  )

  const tasks = React.useMemo(
    () => [
      {
        fn: showModal,
        config: '50 23 * * *',
      },
      {
        fn: getNews,
        config: '00 7 * * *',
      }
    ],
    []
  )

  return (
    <Crontab
      tasks={tasks}
      timeZone="Europe/Moscow"
      dashboard={{
        hidden: true
      }}
    />
  )
}
