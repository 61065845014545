import moment from 'moment'
import React from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Notifications } from '../../../../../Modules/Notifications'
import { callAlert } from '../../../../dynamic-modal'

import { Segment, SecondaryTitle, Actions } from './common'
import { getAvailableRoomsCall } from '../../../../../Modules/api/ccAPI/requests'
import { connect } from 'react-redux'
import { sessionSelectors } from '../../../../../Modules/session'

const ForDateButton = ({ isLoading, select }) => {
  if (isLoading)
    return (
      <Button bsSize="sm" disabled={true}>
        Загрузка..
      </Button>
    )

  return (
    <Button bsSize="sm" onClick={select}>
      Выбрать дату
    </Button>
  )
}

class ForDateView extends React.Component {
  state = {
    isCalendarOpen: false,
    isLoading: false,
  }

  setLoading = bool => this.setState({ isLoading: bool })

  handleDateSelect = async dirtyDate => {
    this.closeCalendar()

    const date = moment(dirtyDate).format('YYYY-MM-DD')
    const nextDate = moment(date)
      .add(1, 'days')
      .format('YYYY-MM-DD')
    this.setLoading(true)

    try {
      const freeRooms = await getAvailableRoomsCall(this.props.lcode, date, nextDate)
      const overview = {}

      for (const room of freeRooms) {
        if (overview[room.name] === undefined) {
          overview[room.name] = 0
        }

        overview[room.name] += 1
      }

      overview['Всего'] = freeRooms.length

      callAlert({
        title: `Свободные комнаты за ${date}`,
        maxContentHeight: 480,
        text: (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '20px' }}>
              <table className="rooms-table">
                <thead>
                <tr>
                  <th>Тип</th>
                  <th>Количество</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(overview).map(([type, count]) => (
                  <tr key={type}>
                    <td>{type}</td>
                    <td>{count}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            <table className="rooms-table">
              <thead>
              <tr>
                <th>Номер</th>
                <th>Тип</th>
              </tr>
              </thead>
              <tbody>
              {freeRooms.map(room => (
                <tr key={room.room_id}>
                  <td>{room.room_id}</td>
                  <td>{room.name}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        ),
      })
    } catch {
      Notifications.failure('Не удалось загрузить данные')
    }

    this.setLoading(false)
  }

  openCalendar = () => this.setState({ isCalendarOpen: true })
  closeCalendar = () => this.setState({ isCalendarOpen: false })

  render() {
    const { isCalendarOpen } = this.state

    return (
      <Segment>
        <SecondaryTitle>За конкретную дату:</SecondaryTitle>
        <Actions>
          <ForDateButton
            isLoading={this.state.isLoading}
            select={this.openCalendar}
          />
        </Actions>
        {isCalendarOpen && (
          <DatePicker
            onChange={this.handleDateSelect}
            withPortal
            inline
            locale="ru"
          />
        )}
      </Segment>
    )
  }
}

const mapStateToProps = state => ({
  lcode: sessionSelectors.lcode(state),
})

const mapDispatchToProps = () => ({})

export const ForDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForDateView)
