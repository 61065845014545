import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import {
  ModalTitle,
  ModalHR,
  ModalText,
  ModalControls,
  ModalButton,
} from '../../../common/ModalParts'
import { useSelector } from 'react-redux'
import { washingSelectors } from '../../../../Modules/washing'
import { reservationSelectors } from '../../../../Modules/reservation'

const mapGeneralErrorToText = {
  'guest_name': 'Имя гостя не заполнено.',
  'booking_number': 'Номер брони не заполнен.',
  'guest_phone': 'Телефон гостя не заполнен.',
  'guest_mail': 'Email гостя не заполнен.',
  'guests_number': 'Количество взрослых гостей не заполнено.',
  'passport':
    'Не указан ни один паспорт,' +
    ' или в каких-то из них не задано имя или номер.',
  'rooms': 'Комната для заселения не выбрана.',
  'days-count': 'В брони слишком много дней. Создайте новую бронь.',
  'same-day-payment_type':
    'В услугах, заказанных на одно и то же число,' +
    ' не могут использоваться разные методы оплаты.',
  'too-many-washing-services': meta => {
    const { newWashingsCount, numberOfFreeMachines } = meta
    return `Вы добавили ${newWashingsCount} стирок, но свободно только ${numberOfFreeMachines} стиральных машинок.`
  },
}

const mapDayErrorToText = {
  date: 'Не выбрана дата.',
  price: 'Не выбрана стоимость.',
  payment_type: 'Не выбран метод оплаты.',
}

const mapServiceErrorToText = {
  date: 'Не выбрана дата.',
  price: 'Не выбрана стоимость.',
  quantity: 'Количество не выбрано или некорректно.',
  payment_type: 'Не выбран метод оплаты / Разные методы оплаты в один день.',
  breakfast_delivery_date: 'Не указана дата доставки завтрака',
}

const getLines = (errors, meta) => {
  const results = {
    general: [],
    days: [],
    services: [],
  }

  for (let error in errors.general) {
    const mapResult = mapGeneralErrorToText[error] || 'Неизвестная ошибка'

    if (typeof mapResult === 'function') {
      results.general.push(mapResult(meta))
    } else {
      results.general.push(mapResult)
    }
  }

  const buildItemsLines = ({ input, mapping, output }) => {
    const counts = {}

    for (let itemId in input) {
      for (let error in input[itemId]) {
        counts[error] = (counts[error] || 0) + 1
      }
    }

    for (let error in counts) {
      const text = mapping[error] || 'Неизвестная ошибка'
      output.push(text + ` (${counts[error]} шт)`)
    }
  }

  buildItemsLines({
    input: errors.days,
    mapping: mapDayErrorToText,
    output: results.days,
  })

  buildItemsLines({
    input: errors.services,
    mapping: mapServiceErrorToText,
    output: results.services,
  })

  return results
}

const GroupHeader = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const LinesBlock = styled.ul`
  margin: 0;
  padding: 0 0 0 12px;
  list-style: none;
`

const Line = styled.li`
  font-size: 14px;
`

const ErrorsGroup = ({ name, lines }) => {
  if (lines.length === 0) return null

  return (
    <React.Fragment>
      <GroupHeader>{name}</GroupHeader>
      <LinesBlock>
        {lines.map(text => (
          <Line key={text}>{text}</Line>
        ))}
      </LinesBlock>
    </React.Fragment>
  )
}

export const ValidationErrorsModal = ({ validationErrors, isOpen, close }) => {
  const newWashingsCount = useSelector(reservationSelectors.newWashingsCount)
  const numberOfFreeMachines = useSelector(
    washingSelectors.numberOfFreeMachines
  )

  const meta = {
    newWashingsCount,
    numberOfFreeMachines,
  }

  const lines = getLines(validationErrors, meta)

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>Ошибки</ModalTitle>
      <ModalHR />
      <ModalText>
        <ErrorsGroup name="Общие данные" lines={lines.general} />
        <ErrorsGroup name="Дни" lines={lines.days} />
        <ErrorsGroup name="Услуги" lines={lines.services} />
      </ModalText>
      <ModalHR />
      <ModalControls>
        <ModalButton
          bsStyle="danger"
          style={{ marginLeft: 'auto' }}
          onClick={close}
        >
          Закрыть
        </ModalButton>
      </ModalControls>
    </Modal>
  )
}
