import { createSelector } from 'reselect'
import { createMCSelector } from '../../../../Modules/helpers/createMultiCacheSelector'
import { processPrepayeds } from '../../../../Modules/helpers/processPrepayeds'

const everything = state => state.todayCheckIns

export const wubook = createSelector(
  everything,
  todayCheckIns => todayCheckIns.wubook
)

export const count = createSelector(everything, todayCheckIns => todayCheckIns.wubookCount)

export const cancelingPKs = createSelector(
  everything,
  todayCheckIns => todayCheckIns.cancelingPKs
)

const prepayeds = createSelector(
  everything,
  todayCheckIns => todayCheckIns.prepayeds
)

export const processedPrepayeds = createMCSelector(prepayeds, prepayeds =>
  processPrepayeds(prepayeds)
)

export const isUpdating = createSelector(
  everything,
  todayCheckIns => todayCheckIns.isUpdating
)

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const loadState = createSelector(
  everything,
  ({ isLoading, isLoaded, isFailed }) => ({ isLoading, isLoaded, isFailed })
)

export const reservationsByBookingNumber = createSelector(
  everything,
  todayCheckIns =>
    todayCheckIns.wubook.reduce((acc, reservation) => {
      acc[reservation.booking_number] = reservation
      return acc
    }, {})
)
