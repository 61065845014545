import * as types from './types'

const initialState = {
  byType: {
    data: [],
    isLoading: false,
    isLoaded: false,
    isFailed: false,
  },
  forDate: {
    date: null,
    count: 0,
    isLoading: false,
    isLoaded: false,
    isFailed: false,
  },
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.LOAD_BY_TYPE_DATA_REQUEST:
      return {
        ...state,
        byType: {
          ...initialState.byType,
          isLoading: true,
        },
      }

    case types.LOAD_BY_TYPE_DATA_SUCCESS:
      return {
        ...state,
        byType: {
          ...state.byType,
          data: payload.data,
          isLoading: false,
          isLoaded: true,
        },
      }

    case types.LOAD_BY_TYPE_DATA_FAILURE:
      return {
        ...state,
        byType: {
          ...state.byType,
          isLoading: false,
          isFailed: true,
        },
      }

    case types.LOAD_FOR_DATE_DATA_REQUEST:
      return {
        ...state,
        forDate: {
          ...initialState.forDate,
          date: payload.date,
          isLoading: true,
        },
      }

    case types.LOAD_FOR_DATE_DATA_SUCCESS:
      return {
        ...state,
        forDate: {
          ...state.forDate,
          count: payload.count,
          isLoading: false,
          isLoaded: true,
        },
      }

    case types.LOAD_FOR_DATE_DATA_FAILURE:
      return {
        ...state,
        forDate: {
          ...state.forDate,
          isLoading: false,
          isFailed: true,
        },
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
