import { connect } from 'react-redux'
import EditReservation from '../Components/reservation/edit/EditReservation'
import {
  onEndChange,
  onStartChange,
  onDayPriceChange,
  onDayMethodChange,
  onReservationPayedChange,
  onDayPayedChange,
  onReservationDayAdd,
  onReservationDayRemove,
  onDaySummCopy,
  onDayMethodCopy,
  onNameChange,
  onPhoneChange,
  onMailChange,
  onBookingNumberChange,
  onNoteChange,
  onClientNoteChange,
  onWubookNoteChange,
  onRefundChange,
  loadServices,
  getReservation,
  onSaleClick,
  onRefundClick,
  toggleEntryModal,
  onNotPayedDayPriceChange,
  onNotPayedDayMethodChange,
  onNotPayedDayPayedChange,
  clearReservation,
  onDayRefund,
  doMigration,
  hideSuccessModal,
  writeCard,
  clearWritecardError,
  hideErrorModal,
  onDontSettleChange,
  onGuestsNumberChange,
  setRooms,
  resetCards,
  hidePrintServerError,
  printCheckAgain,
  onPassportCreate,
  onPassportEdit,
  onPassportRemove,
  addPassportsToBlacklist,
  logPressAction,
  logEditAction,
  logEvent,
  logError,
  deleteReservation,
  selectMode,
  getChecks,
  setChecks,
  getRegistrationForms,
  getLateCheckOutList, hideChangeDaysCountModal,
} from '../Modules/reservation/ReservationModule'
import { bindActionCreators } from 'redux'
import { prepayedsSelectors } from '../Modules/prepayeds'
import { saveExistingReservation } from '../Modules/reservation/saveActions'
import { loyaltyClientActions } from '../Modules/loyalty-client'
import { notesSelectors } from '../Modules/notes'
import { loyaltySelectors } from '../Modules/loyalty'
import { reservationSelectors } from '../Modules/reservation'
import { settingsSelectors } from '../Modules/settings'
import { modalsActions } from '../Modules/modals'
import { breakfastsActions } from '../Modules/breakfasts'
import { checksActions } from '../Components/DayTotals/features/MainPage'
import { sessionSelectors } from '../Modules/session'
import { lateCheckoutsSelectors } from '../Modules/late-checkouts'

const mapStateToProps = state => ({
  isModeSelected: reservationSelectors.isModeSelected(state),
  modes: reservationSelectors.modes(state),

  // Whole reservation data
  reservation: state.reservation,

  // Settings
  settings: state.settings,

  // First and last dates of reservation
  start: state.reservation.start,
  end: state.reservation.end,
  initialEnd: state.reservation.initial_end,
  reservedDays: state.reservation.reserved_days,
  days: reservationSelectors.days(state),
  newDays: reservationSelectors.newDays(state),
  notPayedDays: reservationSelectors.notPayedDays(state),
  payedDays: reservationSelectors.payedDays(state),
  daysDates: reservationSelectors.daysDates(state),
  services: state.reservation.services,
  createdServices: reservationSelectors.serviceServices(state),
  createdUpgrades: reservationSelectors.upgradeServices(state),
  allServices: reservationSelectors.services(state),
  name: state.reservation.guest_name,
  guestsNumber: state.reservation.guests_number > state.reservation.booked_room_occupancy ? state.reservation.booked_room_occupancy : state.reservation.guests_number,
  childrenNumber: state.reservation.children,
  roomDescription: state.reservation.room_description,
  note: state.reservation.note,
  clientNote: state.reservation.client_note,
  wubookNote: state.reservation.wubook_note,
  phone: state.reservation.guest_phone,
  mail: state.reservation.guest_mail,
  bookingNumber: state.reservation.booking_number,
  pk: state.reservation.pk,
  channelName: state.reservation.ota?.name,
  hasRefund: state.reservation.has_refund,
  payed: state.reservation.payed,
  isShowingEntryModal: state.reservation.isShowingEntryModal,
  isSale: state.reservation.isSale,
  isRefund: state.reservation.isRefund,
  isReady: state.reservation.isReady,
  refundSumm: state.reservation.refundSumm,
  rooms: state.reservation.rooms,
  refundedItems: state.reservation.refundedItems,
  showSuccessModal: state.reservation.showSuccessModal,
  showChangeDaysCountModal: state.reservation.showChangeDaysCountModal,
  showErrorModal: state.reservation.showErrorModal,
  isLoading: state.reservation.isLoading,
  isSaving: state.reservation.isSaving,
  lastRoomID: state.reservation.lastRoomID,
  newRoomID: state.reservation.newRoomID,
  hasCard: state.reservation.hasCard,
  writecardError: state.reservation.writecardError,
  availableRooms: state.reservation.availableRooms,
  upgradeableDaysDates: reservationSelectors.upgradeableDaysDates(state),
  dontSettle: state.reservation.dontSettle,
  isFetching: state.reservation.isFetching,
  errorPK: state.reservation.errorPK,
  isPrintServerErrorModalActive:
    state.reservation.isPrintServerErrorModalActive,
  initialData: state.reservation.initialData,
  createdAt: state.reservation.created_at,
  isPrinterOn: state.settings.printerIsOn,
  passport: reservationSelectors.passport(state),
  generalValidationErrors: reservationSelectors.generalValidationErrors(state),
  daysValidationErrors: reservationSelectors.daysValidationErrors(state),
  migrations: reservationSelectors.migrations(state),
  emptyDateSegmentsBetweenInitialMigrations: reservationSelectors.emptyDateSegmentsBetweenInitialMigrations(
    state
  ),
  lastDayDate: reservationSelectors.lastDayDate(state),
  reservedDaysRooms: reservationSelectors.daysRooms(state),
  wasReservationExtended: reservationSelectors.wasReservationExtended(state),
  refundedItemsByType: reservationSelectors.refundedItemsByType(state),
  isWashingOperationsOn: settingsSelectors.isWashingOperationsOn(state),

  prepayeds: prepayedsSelectors.everything(state),
  notes: notesSelectors.everything(state),

  isArchived: reservationSelectors.isArchived(state),

  isLoyaltyEnabled: settingsSelectors.isLoyaltyEnabled(state),
  isLoyaltyUsed: loyaltySelectors.isUsed(state),

  isPayedInAdvance: state.reservation.is_payed_in_advance,
  prePayedBreakfast: state.reservation.prePayedBreakfast,
  isLoyaltyAllowed: state.reservation.is_loyalty_allowed,
  lcode: sessionSelectors.lcode(state),
  isDoorLocks: settingsSelectors.isDoorLocks(state),
  lateCheckoutList: lateCheckoutsSelectors.items(state),

  lastPaidDay: reservationSelectors.lastPaidDay(state),
  doorLockType: settingsSelectors.isDoorLocks(state),
  apiData: settingsSelectors.apiData(state),
  fromOtherHotel: reservationSelectors.fromOtherHotel(state)
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      onEndChange,
      onStartChange,
      onDayPriceChange,
      onDayMethodChange,
      onReservationPayedChange,
      onDayPayedChange,
      onReservationDayAdd,
      onReservationDayRemove,
      onDaySummCopy,
      onDayMethodCopy,
      onNameChange,
      onPhoneChange,
      onMailChange,
      onBookingNumberChange,
      onNoteChange,
      onClientNoteChange,
      onWubookNoteChange,
      onRefundChange,
      loadServices: loadServices,
      getReservation,
      onSaleClick,
      onRefundClick,
      toggleEntryModal,
      onNotPayedDayPriceChange,
      onNotPayedDayMethodChange,
      onNotPayedDayPayedChange,
      saveReservation: saveExistingReservation,
      clearReservation,
      onDayRefund,
      doMigration,
      hideSuccessModal,
      hideChangeDaysCountModal,
      writeCard,
      clearWritecardError,
      hideErrorModal,
      onDontSettleChange,
      onGuestsNumberChange,
      setRooms,
      resetCards,
      hidePrintServerError,
      printCheckAgain,
      onPassportCreate,
      onPassportEdit,
      onPassportRemove,
      addPassportsToBlacklist,
      deleteReservation,
      selectMode,
      getChecks,
      setChecks,
      getRegistrationForms,
      getLateCheckOutList
    },
    dispatch
  ),
  logs: bindActionCreators(
    {
      logPressAction,
      logEditAction,
      logEvent,
      logError,
    },
    dispatch
  ),
  loyaltyClientActions: bindActionCreators(loyaltyClientActions, dispatch),
  checksActions: bindActionCreators(checksActions, dispatch),
  modalsActions: bindActionCreators(modalsActions, dispatch),
  breakfastsActions: bindActionCreators(breakfastsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditReservation)
