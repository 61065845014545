import styled from 'styled-components'

export const PrintButtons = styled.div`
    width: 100%;
    margin: 0 auto 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: end;

    > div {
        margin-right: 10px;
        margin-bottom: 0;
    }

    button {
        height: fit-content;
        margin-bottom: 0;
    }
`
