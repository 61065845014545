import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Navbar from '../Components/Navbar/Navbar'
import { logoutAdmin } from '../Modules/session/actions'
import { settingsSelectors } from '../Modules/settings'

const mapStateToProps = state => ({
  admin: state.session.admin,
  isAuthorized: state.session.isAuthorized,
  printerIsOn: state.settings.printerIsOn,
  lcode: state.session.lcode,
  lcodeObj: state.session.lcodeObj,
  doorLockType: settingsSelectors.isDoorLocks(state),
  apiData: settingsSelectors.apiData(state)
})

const mapDispatchToProps = {
  logoutAdmin,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
