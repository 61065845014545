export function extractTooltipData({ reservationData }) {
  const {
    pk,
    bookingNumber,
    clientName,
    clientPhone,
    clientNote,
    serverNotes,
    totalSumm,
    payedSumm,
  } = reservationData

  return {
    pk,
    bookingNumber,
    clientName,
    clientPhone,
    clientNote,
    serverNotes,
    totalSumm,
    payedSumm,
  }
}
