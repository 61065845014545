import { room_statuses } from './room-statuses'
import React from 'react'
import { callModal, modalTemplates } from '../../../../dynamic-modal'
import { modalActions } from '../../../../dynamic-modal/templates/confirm'


export const statusWarningAlert = async (status) => {
  if (status === room_statuses.clean.title) return true
  const { action } = await callModal(modalTemplates.confirm({
    title: "Статус номера",
    text: <div>
      Данный номер возможно еще не убран. Вы уверены, что хотите заселить в него?
    </div>,
    buttons: ['Нет', 'Да'],
  }))
  return action === modalActions.confirm
}
