import React from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import { rub } from '../../Modules/formatters/currency'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import moment from 'moment'
import uuid from 'uuid'

const FormBlock = styled.div`
  width: 100%;
  max-width: 320px;
  margin: 0 auto 32px;
`

const getMonthOptions = () => {
  const monthOptions = []

  const date = moment()

  for (let offset = 0; offset <= 12; offset++) {
    const value = date.format('MMMM YYYY')

    monthOptions.unshift({
      key: value,
      label: value,
      value,
    })

    date.subtract(1, 'month')
  }

  return monthOptions
}

class HotelSelection extends React.Component {
  state = {
    month: moment().format('MMMM YYYY'),
  }

  handleHotelChange = event => {
    const { value } = event.target

    this.setState({ lcode: value }, this.loadResults)
  }

  handleMonthChange = event => {
    const { value } = event.target

    this.setState({ month: value }, this.loadResults)
  }

  loadResults = () => {
    const { month } = this.state
    const { currentAdminSellsActions } = this.props

    currentAdminSellsActions.load({ month })
  }

  render() {
    const { lcode, month } = this.state
    const { hotelOptions } = this.props
    const { checksV2Enabled } = this.props
    const monthOptions = getMonthOptions()

    return (
      <FormBlock>
        {!checksV2Enabled && (
          <FormGroup>
            <ControlLabel>Объект</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="Выберите объект"
              value={lcode}
              onChange={this.handleHotelChange}
            >
              {hotelOptions.map(hotelOption => (
                <option key={hotelOption.key} value={hotelOption.value}>
                  {hotelOption.label}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        )}
        <FormGroup>
          <ControlLabel>Месяц</ControlLabel>
          <FormControl
            componentClass="select"
            placeholder="Выберите месяц"
            value={month}
            onChange={this.handleMonthChange}
          >
            {monthOptions.map(monthOption => (
              <option key={monthOption.key} value={monthOption.value}>
                {monthOption.label}
              </option>
            ))}
          </FormControl>
        </FormGroup>
      </FormBlock>
    )
  }
}

const ContentBlock = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
`

const StatRow = ({ name, value, booking_number, date, isTotal = false }) => {
  const nameCellContent = isTotal ? <b>{name}</b> : name

  const formattedValue = rub(value)
  const formattedDate = date ? moment(date).format('DD.MM.YYYY') : ''

  const valueCellContent = isTotal ? <b>{formattedValue}</b> : formattedValue

  return (
    <tr>
      <td>{nameCellContent}</td>
      <td>{formattedDate}</td>
      <td>{booking_number}</td>
      <td>{valueCellContent}</td>
    </tr>
  )
}

const EarningsRow = ({ total }) => {
  const formattedValue = rub(total)

  return (
    <tr>
      <td>
        <b>Всего продаж</b>
      </td>
      <td/>
      <td/>
      <td>
        <b>{formattedValue}</b>
      </td>
    </tr>
  )
}

const Content = ({ items, total, totalRaw, loadingState, checksV2Enabled }) => {
  const { isLoading, isLoaded, isFailed } = loadingState

  if (isLoading) {
    return <div>Загрузка..</div>
  }

  if (isFailed) {
    return <div>Произошла ошибка при загрузке данных</div>
  }

  if (!isLoaded) {
    return <div>Нет данных</div>
  }
  if (checksV2Enabled ? !items : items.length === 0) {
    return <div>Нет продаж за этот месяц</div>
  }

  let rows = items.slice().sort((itemA, itemB) => {
    return new Date(itemA.date) - new Date(itemB.date)
  })
  if (checksV2Enabled) {
    rows = rows.map(item => {
      const { service_name, bonus } = item
      return <StatRow key={uuid()} name={service_name} value={bonus} />
    })
  } else {
    rows = rows.map(item => {
      const { name, calculated, date, booking_number } = item
      return <StatRow key={uuid()} name={name} value={calculated}  date={date} booking_number={booking_number}/>
    })
  }
  return (
    <Table
      responsive
      className="table-hover condensed"
      style={{ width: '100%', marginBottom: 0 }}
    >
      <thead>
        <tr>
          <th>Название услуги</th>
          <th>Дата</th>
          <th>Номер бронирования</th>
          <th>Итог за этот месяц</th>
        </tr>
      </thead>
      <tbody>
        {rows}
        <StatRow
          key="Суммарно со всех услуг"
          name="Суммарно со всех услуг"
          value={total}
          isTotal
        />
        <EarningsRow key="Всего продаж" total={totalRaw} />
      </tbody>
    </Table>
  )
}

export const CurrentAdminSellsView = ({
  items,
  total,
  totalRaw,
  loadingState,
  hotelOptions,
  currentAdminSellsActions,
  checksV2Enabled,
}) => {
  return (
    <ContentBlock>
      <HotelSelection
        hotelOptions={hotelOptions}
        currentAdminSellsActions={currentAdminSellsActions}
        checksV2Enabled={checksV2Enabled}
      />
      <Content
        items={items}
        total={total}
        totalRaw={totalRaw}
        loadingState={loadingState}
        checksV2Enabled={checksV2Enabled}
      />
    </ContentBlock>
  )
}
