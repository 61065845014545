import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FreeRooms } from './FreeRooms'
import { freeRoomsSelectors, freeRoomsActions } from '../../reducers/free-rooms'
import { roomsPhysicalActions, roomsPhysicalSelectors } from '../../../../Modules/roomsPhysical'

const mapStateToProps = state => ({
  byType: freeRoomsSelectors.byType(state),
  calculatedByTypeData: freeRoomsSelectors.calculatedByTypeData(state),
  forDate: freeRoomsSelectors.forDate(state),
  roomsPhysical: roomsPhysicalSelectors.everything(state)
})

const mapDispatchToProps = dispatch => ({
  freeRoomsActions: bindActionCreators(freeRoomsActions, dispatch),
  roomsPhysicalActions: bindActionCreators(roomsPhysicalActions, dispatch)
})

export const ConnectedFreeRooms = connect(
  mapStateToProps,
  mapDispatchToProps
)(FreeRooms)
