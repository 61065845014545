import React, { Component, Fragment, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import history from '../../../../Modules/helpers/history'
import { Glyphicon, Pagination, Table } from 'react-bootstrap'
import { Button } from '../../../buttons'
import ReactTooltip from 'react-tooltip'
import { parseReceivedDashedTime } from '../../../../Modules/helpers/parseReceivedTime'
import {
  CancelReservationIcon,
  GroupIcon, IconWrapper,
  NoteIcon,
  PayedInAdvanceIcon,
  PrepayedIcon,
} from '../../../common/IconsWithTooltips'
import { useSelector } from 'react-redux'
import { breakfastsBookedSelectors } from '../../../../Modules/breakfastsBooked'
import { callModal, modalTemplates } from '../../../dynamic-modal'
import { page_size } from '../../../../Constants/others'
import { toNumber } from '../../../../Modules/helpers/converters/currency'
import { CancelBookingQuestionHoverIcon } from './CancelInfoModal'
import { otasListSelectors } from '../../../../Modules/otas-list'

const calculateTotal = (reservation, bookedBreakfasts = []) => {
  let total = 0
  for (let i = 0; i < reservation.reserved_days.length; i++) {
    total = total + parseFloat(reservation.reserved_days[i].price)
  }

  for (let i = 0; i < reservation.additional_services.length; i++) {
    total = total + parseFloat(reservation.additional_services[i].price)
  }
  return toNumber(bookedBreakfasts.reduce((a, b) => a + (b.price * b.qty), total))
}

const calculateBreakfast = (bookedBreakfasts = []) => {
  return toNumber(bookedBreakfasts.reduce((a, b) => a + (b.price * b.qty), 0))
}

const CheckInsTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`
const TH = styled.th`
  padding: 16px 4px!important;
`

const TD = styled.td`
  vertical-align: middle !important;
  padding: 16px 4px!important;
  width: ${props => props.width}px;
  text-align: ${props => props.textAlign};
`

const RTD = styled.td`
  vertical-align: middle !important;
  padding: 16px 4px!important;
  width: ${props => props.width}px;
  text-align: ${props => props.textAlign};
`

const getHintColor = style => {
  switch (style) {
    case 'info':
      return '#3e3e3e'

    case 'danger':
      return '#da4e4e'

    default:
      return '#3e3e3e'
  }
}

const CheckInsHint = styled.p(({ color }) => ({
  fontSize: 14,
  marginTop: 0,
  color: getHintColor(color),
}))

const GRCell = styled.td`
  font-weight: 700;
  color: #555;
  text-align: center;
  cursor: default;

  &:hover {
    background-color: #fff;
  }
`

const SingleReservation = ({
                             reservation,
                             hasPrepayeds,
                             hasNotes,
                             cancelWubookReservation,
                             cancelingWubookPKs,
                             hasSpecialProperties,
                             bookedBreakfasts,
                             otasList

                           }) => {
  return (
    <ReservationRow
      key={reservation.pk}
      reservation={reservation}
      hasPrepayeds={hasPrepayeds}
      hasNotes={hasNotes}
      cancelWubookReservation={cancelWubookReservation}
      isCanceling={cancelingWubookPKs.includes(reservation.pk)}
      hasSpecialProperties={hasSpecialProperties}
      bookedBreakfasts={bookedBreakfasts[reservation.booking_number]}
      otasList={otasList}
    />
  )
}

const GRHeader = ({ hasSpecialProperties }) => (
  <tr>
    <GRCell colSpan={hasSpecialProperties ? 6 : 5}>Групповая бронь</GRCell>
  </tr>
)

const GRFooter = ({ total, hasSpecialProperties }) => (
  <tr>
    <GRCell colSpan={hasSpecialProperties ? 6 : 5}>
      Общая стоимость групповой брони: {total} ₽
    </GRCell>
  </tr>
)

const GroupReservation = ({
                            reservations,
                            hasPrepayeds,
                            hasNotes,
                            cancelWubookReservation,
                            cancelingWubookPKs,
                            hasSpecialProperties,
                            bookedBreakfasts,
                            otasList
                          }) => {
  return (
    <React.Fragment>
      <GRHeader hasSpecialProperties={hasSpecialProperties} />
      {reservations.map((reservation, index) => {
        return (
          <ReservationRow
            isGroup={true}
            hasPrepayeds={hasPrepayeds}
            hasNotes={hasNotes}
            key={reservation.pk}
            reservation={reservation}
            cancelWubookReservation={cancelWubookReservation}
            isCanceling={cancelingWubookPKs.includes(reservation.pk)}
            hasSpecialProperties={hasSpecialProperties}
            bookedBreakfasts={index === 0 ? bookedBreakfasts[reservation.booking_number] : []}
            otasList={otasList}
          />
        )
      })}
      <GRFooter
        total={reservations.reduce((acc, reservation) => {
          return acc + calculateTotal(reservation)
        }, calculateBreakfast(bookedBreakfasts[reservations[0].booking_number]))}
        hasSpecialProperties={hasSpecialProperties}
      />
    </React.Fragment>
  )
}

const TooltipTD = ({ tip, children, ...rest }) => (
  <RTD
    data-for="today-checkins-tooltip"
    data-tip={tip}
    data-scroll-hide="false"
    {...rest}
  >
    {children}
  </RTD>
)

const ReservationRow = ({
                          isGroup,
                          hasPrepayeds,
                          hasNotes,
                          reservation,
                          cancelWubookReservation,
                          isCanceling,
                          hasSpecialProperties,
                          bookedBreakfasts = [],
                          otasList
                        }) => {
  const { booking_number: tip, ota } = reservation
  const otaLogo = otasList[ota.name]
  const [showImg, setShowImg] = useState(true)

  const cancelBooking = async reservation => {
    const { action } = await callModal(
      modalTemplates.confirm({
        title: 'Внимание',
        text: [
          'При отмене бронирования бронь исчезнет из списка заездов. В дальнейшем ее можно будет заселить только вручную.',
          'Вы уверены, что хотите отменить бронь?',
        ],
        buttons: ['Нет', 'Да'],
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }
    cancelWubookReservation(reservation)
  }

  return (
    <tr
      style={{ cursor: 'pointer' }}
      onClick={() => {
        history.push('/reservation?pk=' + reservation.pk)
      }}
    >
      <TooltipTD tip={tip} width={120}>
        {reservation.room_names}
      </TooltipTD>
      <TooltipTD tip={tip} width={100}>
        {reservation.guest_name}
      </TooltipTD>
      <TooltipTD tip={tip} width={100}>
        {moment(reservation.end).format('DD MMM')}
      </TooltipTD>
      {hasSpecialProperties && (
        <TD>
          {isGroup && <GroupIcon style={{ margin: 0 }} />}
          {hasPrepayeds && <PrepayedIcon style={{ margin: 0 }} />}
          {hasNotes && <NoteIcon style={{ margin: 0 }} />}
          {reservation.is_payed_in_advance && <PayedInAdvanceIcon style={{ margin: 0 }} />}
        </TD>
      )}
      <TD>
        {showImg ?
          <IconWrapper tip={ota.name} id={ota.name}>
            <img src={otaLogo} alt={ota.name} onError={() => setShowImg(false)}
                 style={{ maxWidth: "25px", height: "25px", objectFit: "scale-down" }} />
          </IconWrapper>
          : ota.name
        }
      </TD>
      <TooltipTD
        tip={tip}
        width={80}
        style={{ whiteSpace: 'nowrap' }}
      >
        {calculateTotal(reservation, bookedBreakfasts)} ₽
      </TooltipTD>
      <RTD>
        {isCanceling ? (
          <Button size="xs" type="danger" disabled={true}>Загрузка..</Button>
        ) : (
          <Button size="xs" type="danger"
                  style={{margin: 0}}
                  onClick={e => {
                    e.stopPropagation()
                    cancelBooking(reservation)
                  }}
          >
            <CancelReservationIcon/>
          </Button>
        )}
      </RTD>
    </tr>
  )
}

const CheckInsTable = ({
                         data,
                         prepayeds,
                         allNotesByBN,
                         cancelWubookReservation,
                         cancelingWubookPKs,
                       }) => {
  const breakfasts = useSelector(breakfastsBookedSelectors.breakfastsByBooking)
  const otasList = useSelector(otasListSelectors.mapLogoByName)
  if (!data.length) {
    return (
      <div style={{ marginBottom: 10 }}>
        Брони с заселением сегодня не найдены.
      </div>
    )
  }

  const groupedByBN = {}
  data.forEach(r => {
    if (!groupedByBN[r.booking_number]) groupedByBN[r.booking_number] = []
    groupedByBN[r.booking_number].push(r)
  })

  let hasSpecialProperties = false
  for (let bn in groupedByBN) {
    const reservations = groupedByBN[bn]

    const isGroup = reservations.length > 1
    const hasPrepayeds = Boolean(prepayeds[bn])
    const hasNotes = Boolean(allNotesByBN[bn])

    const isPayedInAdvance = reservations.some(reservation => {
      return reservation.is_payed_in_advance
    })

    if (isGroup || hasPrepayeds || hasNotes || isPayedInAdvance) {
      hasSpecialProperties = true
      break
    }
  }

  const mapping = []
  for (let bn in groupedByBN) {
    const reservations = groupedByBN[bn]
    const isGroup = reservations.length > 1

    const key = reservations[0].pk
    const receivedTime = reservations[0].created_at
    const hasPrepayeds = Boolean(prepayeds[bn])
    const hasNotes = Boolean(allNotesByBN[bn])

    const renderedReservation = isGroup ? (
      <GroupReservation
        key={key}
        reservations={reservations}
        hasPrepayeds={hasPrepayeds}
        hasNotes={hasNotes}
        cancelWubookReservation={cancelWubookReservation}
        cancelingWubookPKs={cancelingWubookPKs}
        hasSpecialProperties={hasSpecialProperties}
        bookedBreakfasts={breakfasts}
        otasList={otasList}
      />
    ) : (
      <SingleReservation
        key={key}
        reservation={reservations[0]}
        hasPrepayeds={hasPrepayeds}
        hasNotes={hasNotes}
        cancelWubookReservation={cancelWubookReservation}
        cancelingWubookPKs={cancelingWubookPKs}
        hasSpecialProperties={hasSpecialProperties}
        bookedBreakfasts={breakfasts}
        otasList={otasList}
      />
    )

    mapping.push({
      receivedTime,
      reservation: renderedReservation,
    })
  }

  const renderedItems = mapping
    .sort((i1, i2) => {
      const { receivedTime: rt1 } = i1
      const { receivedTime: rt2 } = i2

      if (!rt1 && !rt2) return 0
      if (!rt1) return 1
      if (!rt2) return -1
      return parseReceivedDashedTime(rt2) - parseReceivedDashedTime(rt1)
    })
    .map(({ reservation }) => reservation)

  return (
    <Fragment>
      <Table
        responsive
        className="table-hover condensed"
        style={{ fontSize: 12, width: '100%' }}
      >
        <thead>
        <tr>
          <TH>Комната</TH>
          <TH>Гость</TH>
          <TH>Дата выезда</TH>
          {hasSpecialProperties && <TH />}
          <TH>ОТА</TH>
          <TH>Цена</TH>
          <TH />
        </tr>
        </thead>
        <tbody>{renderedItems}</tbody>
      </Table>
      <ReactTooltip
        id="today-checkins-tooltip"
        getContent={bn => `Номер: ${bn}`}
        place="top"
      />
      <ReactTooltip id="global-icon-tooltip" place="top" />
    </Fragment>
  )
}

const UpdateButton = styled.span`
  padding: 5px 10px;
  font-size: 12px;
  display: inline-block;
  color: #fff;
  background-color: #444;
  border-radius: 3px;
  line-height: 1.3;
  cursor: pointer;
  ${props => props.disabled && 'opacity: 0.5;'}
`

class TodayCheckIns extends Component {
  state = {
    page: 1,
  }

  setPage = async page => this.setState({ page }, await this.props.todayCheckInsActions.loadData(page))

  renderContent = () => {
    const { loadState, todayCheckInsActions } = this.props
    const { isLoading, isLoaded, isFailed } = loadState

    const pageCount = Math.ceil(this.props.count / page_size)
    const pages = Array.from({ length: pageCount }, (_, i) => i + 1)

    if (isLoading) return (<div>Загрузка..</div>)

    if (isFailed)
      return (
        <React.Fragment>
          <p>Ошибка загрузки данных</p>
          <Button bsSize="sm" onClick={todayCheckInsActions.loadData}>
            Попробовать еще раз
          </Button>
        </React.Fragment>
      )

    if (!isLoaded) return (<div>Нет данных</div>)

    const {
      wubook,
      processedPrepayeds,
      allNotesByBN,
      count,
      cancelingPKs,
    } = this.props

    return (
      <React.Fragment>
        {wubook.length > 0 && (
          <React.Fragment>
            <CheckInsHint color="info">
              Количество заездов сегодня: {count}
            </CheckInsHint>
            <CheckInsHint color="danger">
              Для заселения нажмите на нужную бронь
            </CheckInsHint>
            <CancelBookingQuestionHoverIcon />
          </React.Fragment>
        )}
        <CheckInsTable
          data={wubook}
          prepayeds={processedPrepayeds}
          allNotesByBN={allNotesByBN}
          cancelWubookReservation={todayCheckInsActions.cancelReservation}
          cancelingWubookPKs={cancelingPKs}
        />
        {pageCount > 1 && <Pagination style={{ margin: 0 }}>
          <Pagination.First onClick={() => this.setPage(1)} />
          <Pagination.Prev onClick={() => this.setPage(this.state.page - 1 || 1)} />
          {pages.map(it =>
            <Pagination.Item
              key={it}
              active={this.state.page === it}
              onClick={() => this.setPage(it)}
            >{it}</Pagination.Item>
          )}
          <Pagination.Next onClick={() => {
            if (this.state.page + 1 <= pageCount) this.setPage(this.state.page + 1)
          }} />
          <Pagination.Last onClick={() => this.setPage(pageCount)} />
        </Pagination>}
      </React.Fragment>
    )
  }

  render() {
    const { todayCheckInsActions, isUpdating } = this.props

    const content = this.renderContent()

    const updateButton = isUpdating ? (
      <UpdateButton disabled>Загрузка..</UpdateButton>
    ) : (
      <UpdateButton onClick={todayCheckInsActions.updateData}>
        <Glyphicon glyph="refresh" style={{ marginRight: 8 }} />
        Загрузить новые
      </UpdateButton>
    )

    return (
      <div style={{ padding: 30 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <CheckInsTitle>Заезды сегодня</CheckInsTitle>
          {updateButton}
        </div>

        {content}
      </div>
    )
  }
}

export default TodayCheckIns
