import React from 'react'
import styled from 'styled-components'
import {Table} from 'react-bootstrap'
import moment from 'moment'
import history from '../../Modules/helpers/history'
import {breakfastsBookedSelectors} from '../../Modules/breakfastsBooked'
import {useSelector} from 'react-redux'
import {sessionSelectors} from "../../Modules/session";
import {hotelsSelectors} from "../../Modules/hotels";

const getRooms = reservation => {
    const rooms = []
    reservation.reserved_days.forEach(d => {
        if (d.room && !rooms.includes(d.room.room_id)) rooms.push(d.room.room_id)
    })
    return rooms.join(', ')
}

const calculateTotal = (reservation, breakfasts) => {
    let total = 0
    for (let i = 0; i < reservation.reserved_days.length; i++) {
        total = total + parseFloat(reservation.reserved_days[i].price)
    }

    for (let i = 0; i < reservation.additional_services.length; i++) {
        total = total + parseFloat(reservation.additional_services[i].price)
    }

    const brPrice = breakfasts?.reduce((a, b) => a + (b.price * b.qty), 0) || 0
    return Math.round(brPrice + total)
}

const RTD = styled.td`
    vertical-align: middle;
    padding: 16px 8px;
    width: ${props => props.width}px;
    text-align: ${props => props.textAlign};
`

const ReservationRow = ({reservation, showStatus, breakfasts, hotel, isCurrentHotel}) => {
    const content = <>
        <RTD width={100}>{reservation.booking_number}</RTD>
        <RTD width={150}>{hotel.name}</RTD>
        <RTD width={180}>{reservation.guest_name}</RTD>
        <RTD width={100}>{getRooms(reservation)}</RTD>
        <RTD width={200}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>{moment(reservation.start).format('DD MMMM')}</span>
                <span>==></span>
                <span>{moment(reservation.end).format('DD MMMM')}</span>
            </div>
        </RTD>

        <RTD width={80} textAlign="right">
            {calculateTotal(reservation, breakfasts)} ₽
        </RTD>
        {showStatus && <RTD width={80} textAlign="right">
            {getStatus(reservation.canceled, reservation.is_deleted)}
        </RTD>}
    </>
    return <tr
        style={{cursor: 'pointer'}}
        onClick={() => {
            history.push('/reservation?pk=' + reservation.pk)
        }}
    >
        {content}
    </tr>
}

const getStatus = (is_canceled, is_deleted) => {
    if (is_canceled) {
        return is_deleted ? 'Удалена' : 'Отменена'
    }
    return ''
}

const TypeHint = styled.span`
    background-color: #2caf41;
    color: #fff;
    text-align: center;
    padding: 15px;
    display: block;
`

const Error = styled.span`
    background-color: #e64646;
    color: #fff;
    text-align: center;
    padding: 15px;
    display: block;
`

const SearchResults = ({reservations, hintText, showStatus}) => {
    const prepayedBreakfast = useSelector(breakfastsBookedSelectors.breakfastsByBooking)
    const lcode = useSelector(sessionSelectors.lcode)
    const lcodeByPk = useSelector(hotelsSelectors.lcodeByPk)

    if (!reservations) {
        return <TypeHint>{hintText}</TypeHint>
    }
    if (!reservations.length) {
        return <Error>Ничего не найдено</Error>
    }

    const sortedByDate = reservations.filter(({booking_number}) => booking_number).sort((a, b) =>
        moment(a.start).isAfter(moment(b.start))
            ? 1
            : moment(a.start).isBefore(moment(b.start))
                ? -1
                : 0
    )

    const isGroup = reservations.every((val, i, arr) => val.booking_number === arr[0].booking_number)

    const rows = sortedByDate.map((r, index) => (
        <ReservationRow reservation={r} key={r.pk} showStatus={showStatus} hotel={lcodeByPk[r.lcode.pk]}
                        isCurrentHotel={r.lcode.lcode === lcode}
                        breakfasts={!isGroup || (isGroup && index === 0) ? prepayedBreakfast[r.booking_number] : []}/>
    ))

    return (
        <Table
            responsive
            className="table-hover condensed"
            style={{width: '100%'}}
        >
            <thead>
            <tr>
                <th>Номер брони</th>
                <th>Отель</th>
                <th>Гость</th>
                <th>Комната</th>
                <th>Даты</th>
                <th style={{textAlign: 'right'}}>Цена</th>
                {showStatus && <th>Статус</th>}
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    )
}

export default SearchResults
