import { createSelector } from 'reselect'

export const everything = state => state.freeRooms

export const byType = createSelector(everything, freeRooms => freeRooms.byType)

export const calculatedByTypeData = createSelector(byType, byType => {
  const map = {}

  for (let room of byType.data) {
    if (!map[room.name]) map[room.name] = 0
    map[room.name] += 1
  }

  const result = []

  result.push({
    name: 'Всего',
    count: byType.data.length,
  })

  for (let key in map) {
    result.push({
      name: key,
      count: map[key],
    })
  }

  return result
})

export const forDate = createSelector(
  everything,
  freeRooms => freeRooms.forDate
)

export const forDateCurrentDate = createSelector(
  forDate,
  forDate => forDate.date
)
