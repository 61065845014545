import React from 'react'
import { useSelector } from 'react-redux'
import { reservationSelectors } from '../../../Modules/reservation'
import { EditSection } from '../../common/EditSection'
import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { specialServiceTypesSelectors } from '../../../Modules/special-service-types'

export const Parking = ({
  availableActions = {
    create: true,
    update: true,
    copy: true,
    delete: true,
    refund: false,
  },
}) => {
  const isParkingServiceLoaded = useSelector(
    specialServiceTypesSelectors.isLoaded
  )

  const isLoading = !isParkingServiceLoaded

  const parkingServices = useSelector(reservationSelectors.parkingServices)
  const availableServices = useSelector(reservationSelectors.availableServices)
  const validationErrors = useSelector(
    reservationSelectors.servicesValidationErrors
  )

  const content = parkingServices.length > 0 && (
    <Content
      validationErrors={validationErrors}
      availableActions={availableActions}
    />
  )

  const footer = availableActions.create && (
    <Footer availableServices={availableServices} />
  )

  return (
    <EditSection
      name="Парковка"
      content={content}
      footer={footer}
      isLoading={isLoading}
    />
  )
}
