import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { paymentTypesNoBank, paymentTypesList } from '../../../../../Modules/helpers/paymentTypes'
import { Select, InputNumber, Input } from '../../../../form-stuff'
import { ButtonGroup, Button } from '../../../../buttons'
import {
  defaultPaymentTypeOption,
  defaultQuantityOption,
} from '../../../constants/default-values'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../common/hooks/use-actions'
import * as servicesActions from '../../../../../Modules/reservation/actions/services'
import { reservationSelectors } from '../../../../../Modules/reservation'
import { useDateSelectData } from '../../../common/hooks/use-date-select-data'
import { specialServiceTypesSelectors } from '../../../../../Modules/special-service-types'
import styled from 'styled-components'
import { parkingService } from '../../../../../Modules/special-service-types/selectors'

const Notice = styled.p`
  color: #da4e4e;
  font-size: 14px;
  margin: 0;
  text-align: center;
`

export const Footer = () => {
  const availableDates = useSelector(reservationSelectors.availableDates)
  const parkingServices = useSelector(specialServiceTypesSelectors.parkingService)
  const defaultPrice = parkingServices ? parkingServices.price : 0

  const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

  const {
    date,
    setDate,
    resetDate,
    dateOptions,
    dateDefaultOption,
  } = useDateSelectData(availableDates)

  useEffect(() => {
    const list = parkingServices ? parkingServices.is_available_for_sale_by_bank
        ? paymentTypesList
        : paymentTypesNoBank
      : paymentTypesList
    setPaymentTypeList(list)
    setPaymentType('')
  }, [parkingServices])

  const [price, setPrice] = useState(defaultPrice)
  const [quantity, setQuantity] = useState('')
  const [paymentType, setPaymentType] = useState('')

  const resetValues = () => {
    resetDate()
    setPrice(defaultPrice)
    setQuantity('')
    setPaymentType('')
  }

  const [errors, setErrors] = useState([])
  const resetErrors = () => setErrors([])

  const { createService } = useActions(servicesActions)

  const handleSubmit = () => {
    resetErrors()

    const currentErrors = []

    if (!date) currentErrors.push('date')
    if (!price) currentErrors.push('price')
    if (!quantity) currentErrors.push('quantity')
    if (!paymentType) currentErrors.push('paymentType')

    if (currentErrors.length > 0) {
      setErrors(currentErrors)
      return
    }

    createService({
      serviceType: 'parking',
      name: 'Парковка',
      price,
      quantity,
      paymentType,
      date,
      special_service_id: parkingServices.pk
    })

    resetValues()
  }

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Col xs={3}>
          <Select
            label="Дата"
            defaultOption={dateDefaultOption}
            disabled={dateOptions.length === 0}
            options={dateOptions}
            value={date}
            onChange={setDate}
            hasError={errors.includes('date')}
          />
        </Col>
        <Col xs={3}>
          <Input
            type="number"
            placeholder="Введите стоимость"
            label="Стоимость за штуку"
            value={price}
            onChange={setPrice}
            hasError={errors.includes('price')}
          />
          <Notice>Стоимость парковки {defaultPrice} руб. в сутки</Notice>
        </Col>
        <Col xs={3}>
          <InputNumber
            label="Количество"
            defaultValue={defaultQuantityOption}
            value={quantity}
            onChange={setQuantity}
            hasError={errors.includes('quantity')}
          />
        </Col>
        <Col xs={3}>
          <Select
            label="Метод оплаты"
            defaultOption={defaultPaymentTypeOption}
            options={paymentTypeList}
            value={paymentType}
            onChange={setPaymentType}
            hasError={errors.includes('paymentType')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3} xsOffset={9}>
          <ButtonGroup>
            <Button
              type="success"
              onClick={handleSubmit}
              style={{ width: '100%' }}
            >
              Добавить парковку
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </React.Fragment>
  )
}
