import reservationDuration from '../helpers/reservationDuration'
import getArrayWithUpdatedItem from '../helpers/getArrayWithUpdatedItem'
import moment from 'moment'
import uuid from 'uuid/v4'
import * as types from './types'
import Logs from '../Logs'
import CheckPrintController from '../CheckPrintController'
import { earlyCheckInService, lateCheckOutService } from '../helpers/servicesNames'
import { defaultCardCheckOutTime, defaultCheckInTime, defaultCheckOutTime } from '../helpers/defaultTimes'
import { prepayedsActions } from '../prepayeds'
import { loyaltyClientActions } from '../loyalty-client'
import { loyaltyPurchaseActions } from '../loyalty-purchase'
import nanoid from 'nanoid'
import { notesActions } from '../notes'
import { dayTypes, MODES, serviceTypes } from '../helpers/types'
import { changeCheckInTime, changeCheckOutTime, refundAllServices, syncCheckServices } from './actions/services'
import clone from 'clone'
import { LogsAPI } from '../logs-api'
import { Notifications } from '../Notifications'
import { ccAPI } from '../api/ccAPI'
import { getServiceType } from './get-service-type'
import * as reservationSelectors from './selectors'
import { day as dayFormatter } from '../formatters/date'
import { AlphaBookingNumberRE } from '../helpers/regex/AlphaBookingNumberRE'
import { callAlert, callModal, callPrompt, modalTemplates } from '../../Components/dynamic-modal'
import { settingsSelectors } from '../settings'
import { cancelBonusesForItems } from '../helpers/loyalty/cancel-bonuses-for-items'
import { LoyaltyError } from '../api/loyaltyAPI/helpers/LoyaltyError'
import { applyBonusesForPaidItems } from '../loyalty-purchase/helpers/apply-bonuses-for-paid-items'
import { sessionSelectors } from '../session'
import { loyaltySelectors } from '../loyalty'
import { isPassportValid } from '../helpers/isPassportValid'
import { washingSelectors } from '../washing'
import { specialServiceTypesSelectors } from '../special-service-types'
import * as hotelsActions from '../hotels/actions'
import { checksActions } from '../../Components/DayTotals/features/MainPage'
import { checksSelectors } from '../../Components/DayTotals/features/MainPage/reducers/checks'
import {
  deleteReservationCall,
  getAvailableRoomsCall,
  getReservationCall,
  getWubookModifiedPricesCall,
  getWubookPricesCall,
  updateCardsCall,
} from "../api/ccAPI/requests";
import { cardAPI } from "../api/cardApi";
import { registrationFormsActions } from '../registration-forms'
import { lateCheckoutsActions } from '../late-checkouts'
import { defaultLockCheckinTime, defaultLockCheckOutTime, doorLocksTypes, locksDateTimeFormat } from '../settings/utils'
import { isRoomNotAvailable } from '../helpers/check-category-availability'
import { roomsSelectors } from '../rooms'
import {
  getDeletePrepayedBreakfastList
} from '../../Components/Services/PrepaidBreakfast/DeletePrepayedBreakfastsModal'
import { statusWarningAlert } from '../../Components/MainPage/components/grid/room-statuses/room-status-alert'

if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function(target, firstSource) {
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object')
      }

      var to = Object(target)
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i]
        if (nextSource === undefined || nextSource === null) {
          continue
        }

        var keysArray = Object.keys(Object(nextSource))
        for (
          var nextIndex = 0, len = keysArray.length;
          nextIndex < len;
          nextIndex++
        ) {
          var nextKey = keysArray[nextIndex]
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey)
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey]
          }
        }
      }
      return to
    },
  })
}

const START_CHANGED = 'ghotel/reservation/START_CHANGED'
const END_CHANGED = 'ghotel/reservation/END_CHANGED'
const DAY_PRICE_CHANGED = 'ghotel/reservation/DAY_PRICE_CHANGED'
const DAY_PAYED_CHANGED = 'ghotel/reservation/DAY_PAYED_CHANGED'
const DAY_METHOD_CHANGED = 'ghotel/reservation/DAY_METHOD_CHANGED'
const RESERVATION_PAYED_CHANGED = 'ghotel/reservation/RESERVATION_PAYED_CHANGED'
const SET_SERVICES = 'ghotel/reservation/SET_SERVICES'
const SERVICE_ERROR_SET = 'ghotel/reservation/SERVICE_ERROR_SET'
const DAY_ADD = 'ghotel/reservation/DAY_ADD'
const DAY_REMOVE = 'ghotel/reservation/DAY_REMOVE'
const DAY_SUMM_COPY = 'ghotel/reservation/DAY_SUMM_COPY'
const DAY_METHOD_COPY = 'ghotel/reservation/DAY_METHOD_COPY'
const NAME_CHANGED = 'ghotel/reservation/NAME_CHANGED'
const BOOKING_NUMBER_CHANGED = 'ghotel/reservation/BOOKING_NUMBER_CHANGED'
const BOOKING_NUMBER_SELECTED = 'ghotel/reservation/BOOKING_NUMBER_SELECTED'
const PHONE_CHANGED = 'ghotel/reservation/PHONE_CHANGED'
const GUESTS_NUMBER_CHANGED = 'ghotel/reservation/GUESTS_NUMBER_CHANGED'
export const MAIL_CHANGED = 'ghotel/reservation/MAIL_CHANGED'
const NOTE_CHANGED = 'ghotel/reservation/NOTE_CHANGED'
const CLIENT_NOTE_CHANGED = 'ghotel/reservation/CLIENT_NOTE_CHANGED'
const WUBOOK_NOTE_CHANGED = 'ghotel/reservation/WUBOOK_NOTE_CHANGED'
const REFUND_CHANGED = 'ghotel/reservation/REFUND_CHANGED'
const SHOW_REFUND_MODAL = 'ghotel/reservation/SHOW_REFUND_MODAL'
const HIDE_REFUND_MODAL = 'ghotel/reservation/HIDE_REFUND_MODAL'
const SHOW_REFUND_ACTION_MODAL = 'ghotel/reservation/SHOW_REFUND_ACTION_MODAL'
const HIDE_REFUND_ACTION_MODAL = 'ghotel/reservation/HIDE_REFUND_ACTION_MODAL'
const UPGRADE_DELETE = 'ghotel/reservation/UPGRADE_DELETE'
const UPGRADE_ERROR_SET = 'ghotel/reservation/UPGRADE_ERROR_SET'
const UPGRADE_ERROR_CLEAR = 'ghotel/reservation/UPGRADE_ERROR_CLEAR'
export const CHECK_IN_CHANGED = 'ghotel/reservation/CHECK_IN_CHANGED'
export const CHECK_OUT_CHANGED = 'ghotel/reservation/CHECK_OUT_CHANGED'
const RESERVATION_SET = 'ghotel/reservation/RESERVATION_SET'
const AVAILABLE_ROOMS_SET = 'ghotel/reservation/AVAILABLE_ROOMS_SET'
const RESERVATION_CLEAR = 'ghotel/reservation/RESERVATION_CLEAR'
const CREATE_NEW_RESERVATION = 'ghotel/reservation/CREATE_NEW_RESERVATION'
const TOGGLE_ENTRY_MODAL = 'ghotel/reservation/TOGGLE_ENTRY_MODAL'
const SET_SALE = 'ghotel/reservation/SET_SALE'
const SET_REFUND = 'ghotel/reservation/SET_REFUND'
const NOT_PAYED_PRICE_CHANGED = 'ghotel/reservation/NOT_PAYED_PRICE_CHANGED'
const NOT_PAYED_METHOD_CHANGED = 'ghotel/reservation/NOT_PAYED_METHOD_CHANGED'
const NOT_PAYED_PAYED_CHANGED = 'ghotel/reservation/NOT_PAYED_PAYED_CHANGED'
const REFUND_DAY = 'ghotel/reservation/REFUND_DAY'
const SET_ERROR_FIELDS = 'ghotel/reservation/SET_ERROR_FIELDS'
const CLEAR_ERROR_FIELDS = 'ghotel/reservation/CLEAR_ERROR_FIELDS'
const SET_PAYMENT_METHOD_ERROR = 'ghotel/reservation/SET_PAYMENT_METHOD_ERROR'
const CLEAR_PAYMENT_METHOD_ERROR = 'ghotel/reservation/CLEAR_PAYMENT_METHOD_ERROR'
const CLOSE_PAYMENT_METHOD_ERROR = 'ghotel/reservation/CLOSE_PAYMENT_METHOD_ERROR'
const SET_LATE_CHECKOUT_DATE = 'ghotel/reservation/SET_LATE_CHECKOUT_DATE'
const CLOSE_VALIDATION_ERRORS_MODAL =
  'ghotel/reservation/CLOSE_VALIDATION_ERRORS_MODAL'
const MIGRATION_DONE = 'ghotel/reservation/MIGRATION_DONE'
export const SUCCESS_MODAL_SHOW = 'ghotel/reservation/SUCCESS_MODAL_SHOW'
const ERROR_MODAL_SHOW = 'ghotel/reservation/ERROR_MODAL_SHOW'
const ERROR_MODAL_HID = 'ghotel/reservation/ERROR_MODAL_HID'
const SUCCESS_MODAL_HID = 'ghotel/reservation/SUCCESS_MODAL_HID'
export const SHOW_CHANGE_DAYS_COUNT_MODAL = 'ghotel/reservation/SHOW_CHANGE_DAYS_COUNT_MODAL'
const HIDE_CHANGE_DAYS_COUNT_MODAL = 'ghotel/reservation/HIDE_CHANGE_DAYS_COUNT_MODAL'
export const LOADING_SHOW = 'ghotel/reservation/LOADING_SHOW'
export const LOADING_HID = 'ghotel/reservation/LOADING_HID'
const INCREASE_CARDS = 'ghotel/reservation/INCREASE_CARDS'
const SET_WRITECARD_ERROR = 'ghotel/reservation/SET_WRITECARD_ERROR'
const CLEAR_WRITECARD_ERROR = 'ghotel/reservation/CLEAR_WRITECARD_ERROR'
export const SET_PK = 'ghotel/reservation/SET_PK'
const DONT_SETTLE_CHANGED = 'ghotel/reservation/DONT_SETTLE_CHANGED'
export const SET_DUPLICATES = 'ghotel/reservation/SET_DUPLICATES'
const CLEAR_DUPLICATES = 'ghotel/reservation/CLEAR_DUPLICATES'
const CONFIRM_DUPLICATE = 'ghotel/reservation/CONFIRM_DUPLICATE'
const SET_DAY_PRICES = 'ghotel/reservation/SET_DAY_PRICES'
const APPLY_DAY_PRICES = 'ghotel/reservation/APPLY_DAY_PRICES'
const SET_ROOMS = 'ghotel/reservation/SET_ROOMS'
const RESET_CARDS = 'ghotel/reservation/RESET_CARDS'
const SHOW_PRINT_SERVER_ERROR = 'ghotel/reservation/SHOW_PRINT_SERVER_ERROR'
const HIDE_PRINT_SERVER_ERROR = 'ghotel/reservation/HIDE_PRINT_SERVER_ERROR'
const SET_LOADING = 'ghotel/reservation/SET_LOADING'
const CONFIRM_REFUND = 'ghotel/reservation/CONFIRM_REFUND'
const PASSPORT_CREATE = 'ghotel/reservation/PASSPORT_CREATE'
const PASSPORT_EDIT = 'ghotel/reservation/PASSPORT_EDIT'
const PASSPORT_REMOVE = 'ghotel/reservation/PASSPORT_REMOVE'
export const RESET_CHECK_IN = 'ghotel/reservation/RESET_CHECK_IN'
export const RESET_CHECK_OUT = 'ghotel/reservation/RESET_CHECK_OUT'
const SET_PAYED = 'ghotel/reservation/SET_PAYED'
const START_SAVING = 'ghotel/reservation/START_SAVING'
const END_SAVING = 'ghotel/reservation/END_SAVING'
const GET_BONUSES_FROM_PAID_ITEMS_REQUEST =
  'ghotel/reservation/GET_BONUSES_FROM_PAID_ITEMS_REQUEST'
const GET_BONUSES_FROM_PAID_ITEMS_SUCCESS =
  'ghotel/reservation/GET_BONUSES_FROM_PAID_ITEMS_SUCCESS'
const GET_BONUSES_FROM_PAID_ITEMS_FAILURE =
  'ghotel/reservation/GET_BONUSES_FROM_PAID_ITEMS_FAILURE'
const SELECT_MODE = 'ghotel/reservation/SELECT_MODE'
const SET_PREPAYED_BREAKFAST = 'ghotel/reservation/SET_BREAKFAST'
export const SET_SEND_CONFIRMATION_TO_EMAIL =
  'ghotel/reservation/SET_SEND_CONFIRMATION_TO_EMAIL'
const SET_REFUND_CHECK_ID = 'ghotel/reservation/SET_REFUND_CHECK_ID'
const SET_BOOKING_NUMBER = 'ghotel/reservation/SET_BOOKING_NUMBER'

const initial_state = {
  pk: '',
  start: null,
  end: null,
  initial_end: null,
  check_in_time: '13:45:00',
  check_out_time: '12:00:00',
  initial_reserved_days_count: 0,
  reserved_days: [],
  additional_services: [],
  services_error: '',
  upgrades_error: '',
  has_refund: false,
  isRefundModalOpened: false,
  isRefundActionModalOpened: false,
  payed: false,
  guest_name: '',
  booking_number: `${new Date().getTime()}Б`,
  isBookingNumberSelected: false,
  admin: '',
  note: '',
  hasCard: 0,
  wubook_note: '',
  guest_phone: '',
  guest_mail: '',
  client_note: '',
  channel_name: '',
  passport_number: '',
  passport: [
    {
      id: nanoid(),
      name: '',
      passport: '',
    },
  ],
  isReady: true,
  guests_number: 1,
  children: 0,
  room_description: null,
  duration: 0,
  services: [],
  specialServices: [],
  bnVariants: ['Без б', 'без брони', 'переселение', 'продление'],
  isShowingEntryModal: false,
  isRefund: false,
  isSale: false,
  refundSumm: 0,
  rooms: [],
  hasValidationErrors: false,
  validationErrors: {
    general: {},
    days: {},
    services: {},
  },
  isValidationErrorsModalOpen: false,
  isPaymentMethodErrorsModalOpen: false,
  hasPaymentValidationErrors: false,
  showModalWithAllErrors: false,
  refundedItems: [],
  showSuccessModal: false,
  showChangeDaysCountModal: false,
  showErrorModal: false,
  errorPK: null,
  isLoading: false,
  isSaving: false,
  isFetching: true,
  lastRoomID: null,
  newRoomID: null,
  writecardError: null,
  availableRooms: [],
  selectedRoom: null,
  dontSettle: false,
  isDuplicatesFound: false,
  duplicatesData: {
    duplicates: [],
    message: '',
  },
  isDuplicateConfirmed: false,
  dayPrices: {},
  isPrintServerErrorModalActive: false,
  created_at: null,
  refundConfirmed: false,
  canceled: false,
  is_loyalty_allowed: true,
  isGettingBonusesFromPaidItems: false,
  cardsClearedAfterRelocation: false,

  mode: MODES.INITIAL,
  prePayedBreakfast: null,
  sendConfirmToEmail: false,
  livingService: null,
  refundCheckList: null,
  lcode: null,
}

const initialData = clone(initial_state)

initial_state.initialData = initialData

export default function reducer(state = initial_state, action) {
  let { payload } = action

  let getUpdatedDays

  const getFirstDay = () => state.reserved_days[0]
  const getLastDay = () => state.reserved_days[state.reserved_days.length - 1]

  switch (action.type) {
    case PASSPORT_CREATE:
      return {
        ...state,
        passport: [
          ...state.passport,
          { id: nanoid(), name: payload.name, passport: '' },
        ],
      }

    case SET_BOOKING_NUMBER:
      return {
        ...state,
        booking_number: state.booking_number ? state.booking_number : `${new Date().getTime()}Б`,
      }

    case PASSPORT_EDIT:
      return {
        ...state,
        passport: state.passport.map(item => {
          if (item.id !== payload.id) return item

          return {
            ...item,
            [payload.key]: payload.value,
          }
        }),
      }

    case PASSPORT_REMOVE:
      return {
        ...state,
        passport: state.passport.filter(item => item.id !== payload.id),
      }

    case CONFIRM_REFUND:
      return {
        ...state,
        refundConfirmed: true,
      }

    case SET_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      }

    case SHOW_PRINT_SERVER_ERROR:
      return {
        ...state,
        isPrintServerErrorModalActive: true,
      }

    case HIDE_PRINT_SERVER_ERROR:
      return {
        ...state,
        isPrintServerErrorModalActive: false,
      }

    case RESET_CARDS:
      return {
        ...state,
        hasCard: 0,
      }

    case SET_DAY_PRICES:
      return {
        ...state,
        dayPrices: payload.dayPrices,
      }

    case APPLY_DAY_PRICES:
      return {
        ...state,
        reserved_days: payload.nextReservedDays,
      }

    case CONFIRM_DUPLICATE:
      return {
        ...state,
        isDuplicateConfirmed: true,
      }

    case SET_DUPLICATES:
      return {
        ...state,
        isDuplicatesFound: true,
        duplicatesData: payload,
      }

    case CLEAR_DUPLICATES:
      return {
        ...state,
        isDuplicatesFound: false,
        duplicatesData: {
          duplicates: [],
          message: '',
        },
      }

    case DONT_SETTLE_CHANGED:
      return {
        ...state,
        dontSettle: payload,
      }

    case SET_PK:
      return {
        ...state,
        pk: payload,
      }

    case INCREASE_CARDS:
      return {
        ...state,
        hasCard: state.hasCard + 1,
        writecardError: null,
      }

    case SET_WRITECARD_ERROR:
      return {
        ...state,
        writecardError: payload,
      }

    case CLEAR_WRITECARD_ERROR:
      return {
        ...state,
        writecardError: null,
      }

    case SUCCESS_MODAL_SHOW:
      return {
        ...state,
        showSuccessModal: true,
      }

    case SUCCESS_MODAL_HID:
      return {
        ...state,
        showSuccessModal: false,
      }

    case HIDE_CHANGE_DAYS_COUNT_MODAL:
      return {
        ...state,
        showChangeDaysCountModal: false,
      }

    case SHOW_CHANGE_DAYS_COUNT_MODAL:
      return {
        ...state,
        showChangeDaysCountModal: true,
      }

    case ERROR_MODAL_SHOW:
      return {
        ...state,
        showErrorModal: true,
        errorPK: payload,
      }

    case ERROR_MODAL_HID:
      return {
        ...state,
        showErrorModal: false,
        errorPK: null,
      }

    case START_SAVING:
      return {
        ...state,
        isSaving: true,
      }

    case END_SAVING:
      return {
        ...state,
        isSaving: false,
      }

    case LOADING_SHOW:
      return {
        ...state,
        isLoading: true,
      }

    case LOADING_HID:
      return {
        ...state,
        isLoading: false,
      }

    case SET_ROOMS:
      getUpdatedDays = days =>
        days.map(d => ({
          ...d,
          room: payload,
        }))

      return {
        ...state,
        reserved_days: getUpdatedDays(state.reserved_days),
        newRoomID: payload.room_id,
        lastRoomID: payload.room_id,
        rooms: [{ id: payload.room_id, name: payload.name }],
      }

    case MIGRATION_DONE:
      const firstMigrationDate = state.reserved_days.find(
        d => d.date === payload.date
      ).date

      const firstMigrationDateObj = moment(firstMigrationDate)

      const getDaysAfterMigration = array =>
        array.map(d => {
          if (moment(d.date) >= firstMigrationDateObj)
            return {
              ...d,
              room: payload.room,
            }

          return d
        })

      const nRD = getDaysAfterMigration(state.reserved_days)

      const roomsAfterMigration = []
      if (state.isReady) {
        let lastRoomID = null
        nRD.forEach(d => {
          const room = d.room
          const roomID = room.room_id
          if (roomID !== lastRoomID) {
            roomsAfterMigration.push({
              id: roomID,
              date: roomsAfterMigration.length ? d.date : undefined,
            })
            lastRoomID = roomID
          }
        })
      }

      return {
        ...state,
        reserved_days: nRD,
        rooms: roomsAfterMigration,
        newRoomID: payload.room.room_id,
        hasCard: 0,
        isLoading: false,
      }

    case SET_ERROR_FIELDS:
      return {
        ...state,
        hasValidationErrors: true,
        validationErrors: payload,
        isValidationErrorsModalOpen: true,
      }

    case CLEAR_ERROR_FIELDS:
      return {
        ...state,
        hasValidationErrors: false,
        validationErrors: initial_state.validationErrors,
      }

    case CLOSE_VALIDATION_ERRORS_MODAL:
      return {
        ...state,
        isValidationErrorsModalOpen: false,
      }

    case SET_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        hasPaymentValidationErrors: true,
        isPaymentMethodErrorsModalOpen: true,
      }

    case CLEAR_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        hasPaymentValidationErrors: false,
        isPaymentMethodErrorsModalOpen: false,
      }

    case CLOSE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        isPaymentMethodErrorsModalOpen: false,
      }

    case START_CHANGED: {
      const { date, adminName } = payload

      const oldDuration = reservationDuration(state.start, state.end)
      const newDuration = reservationDuration(date, state.end)
      const newStart = date
      const firstDay = getFirstDay()

      if (newDuration === oldDuration) {
        return state
      }

      if (newDuration < oldDuration) {
        const newStartMoment = moment(newStart)

        const updatedReservedDays = state.reserved_days.filter(day => {
          return moment(day.date).isSameOrAfter(newStartMoment)
        })

        return {
          ...state,
          start: newStart,
          duration: newDuration,
          reserved_days: updatedReservedDays,
        }
      }

      const addedDays = []
      const firstDayDate = firstDay.date
      const firstDayDateMoment = moment(firstDayDate)

      // const initialEnd = state.initial_end
      // const initialEndMoment = moment(initialEnd)

      for (let offset = 1; offset <= newDuration - oldDuration; offset++) {
        const dateMoment = firstDayDateMoment.clone().subtract(offset, 'days')
        const type = dayTypes.new

        const newDayOptions = {
          pk: 0,
          id: nanoid(),
          type,
          date: dateMoment.format('YYYY-MM-DD'),
          admin: adminName,
          refund_id: '',
          payed_at: '',
        }

        addedDays.unshift({
          ...firstDay,
          ...newDayOptions,
        })
      }

      const updatedReservedDays = addedDays.concat(state.reserved_days)

      return {
        ...state,
        start: newStart,
        duration: newDuration,
        reserved_days: updatedReservedDays,
      }
    }

    case END_CHANGED: {
      const { date, adminName } = payload

      const oldDuration = reservationDuration(state.start, state.end)
      const newDuration = reservationDuration(state.start, date)
      const newEnd = date
      const lastDay = getLastDay()

      if (newDuration === oldDuration) {
        return state
      }

      if (newDuration < oldDuration) {
        const newEndMoment = moment(newEnd)

        const updatedReservedDays = state.reserved_days.filter(day => {
          return moment(day.date).isBefore(newEndMoment)
        })

        return {
          ...state,
          end: newEnd,
          duration: newDuration,
          reserved_days: updatedReservedDays,
        }
      }

      const addedDays = []
      const lastDayDate = lastDay.date
      const lastDayDateMoment = moment(lastDayDate)

      const initialEnd = state.initial_end
      const initialEndMoment = moment(initialEnd)

      for (let offset = 1; offset <= newDuration - oldDuration; offset++) {
        const dateMoment = lastDayDateMoment.clone().add(offset, 'days')

        const type =
          initialEndMoment.isAfter(dateMoment) && !state.isReady
            ? dayTypes.notPayed
            : dayTypes.new

        const newDayOptions = {
          pk: 0,
          id: nanoid(),
          type,
          date: dateMoment.format('YYYY-MM-DD'),
          admin: adminName,
          refund_id: '',
          payed_at: '',
        }

        addedDays.push({
          ...lastDay,
          ...newDayOptions,
        })
      }

      const updatedReservedDays = state.reserved_days.concat(addedDays)

      return {
        ...state,
        end: newEnd,
        duration: newDuration,
        reserved_days: updatedReservedDays,
      }
    }

    case DAY_PRICE_CHANGED:
      const newDayPrice = Number(payload.price) || ''

      return {
        ...state,
        reserved_days: getArrayWithUpdatedItem(
          state.reserved_days,
          payload.id,
          'price',
          newDayPrice
        ),
      }

    case DAY_METHOD_CHANGED:
      return {
        ...state,
        reserved_days: getArrayWithUpdatedItem(
          state.reserved_days,
          payload.id,
          'payment_type',
          payload.payment_type
        ),
      }

    case DAY_PAYED_CHANGED:
      const newDayPayed = payload.payed ? moment().format('YYYY-MM-DD') : ''

      const newRD = getArrayWithUpdatedItem(
        state.reserved_days,
        payload.id,
        'payment_date',
        newDayPayed
      )

      return {
        ...state,
        reserved_days: newRD,
      }

    case NOT_PAYED_PRICE_CHANGED:
      const newNPDPrice = payload.price ? +payload.price : ''

      return {
        ...state,
        reserved_days: getArrayWithUpdatedItem(
          state.reserved_days,
          payload.id,
          'price',
          newNPDPrice
        ),
      }

    case NOT_PAYED_METHOD_CHANGED:
      const newNPDMethod = payload.payment_type

      return {
        ...state,
        reserved_days: getArrayWithUpdatedItem(
          state.reserved_days,
          payload.id,
          'payment_type',
          newNPDMethod
        ),
      }

    case NOT_PAYED_PAYED_CHANGED: {
      const newNPDPaymentDate = payload.payed
        ? moment().format('YYYY-MM-DD')
        : ''

      const newRD = getArrayWithUpdatedItem(
        state.reserved_days,
        payload.id,
        'payment_date',
        newNPDPaymentDate
      )

      return {
        ...state,
        reserved_days: newRD,
      }
    }

    case DAY_REMOVE: {
      let maxDate = 0
      let lastDay = null

      for (let day of state.reserved_days) {
        const date = Number(moment(day.date))

        if (date > maxDate) {
          maxDate = date
          lastDay = day
        }
      }

      const nextDays = state.reserved_days.filter(day => day.id !== lastDay.id)

      return {
        ...state,
        end: moment(state.end)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        duration: state.duration - 1,
        reserved_days: nextDays,
        additional_les: state.additional_services.filter(
          s =>
            s.service === earlyCheckInService ||
            s.service === lateCheckOutService ||
            s.date !== lastDay.date
        ),
      }
    }

    case RESERVATION_PAYED_CHANGED: {
      const newIsPayed = !state.payed

      const newPaymentDate = newIsPayed ? moment().format('YYYY-MM-DD') : ''

      const updateDays = days =>
        days.map(day => {
          if (day.type === dayTypes.payed) {
            return day
          }

          return {
            ...day,
            payment_date: newPaymentDate,
          }
        })

      return {
        ...state,
        payed: newIsPayed,
        reserved_days: updateDays(state.reserved_days),
      }
    }

    case SET_SERVICES:
      return {
        ...state,
        services: payload.common,
        specialServices: payload.special,
        livingService: payload.living,
        allServices: payload.allServices
      }

    case SERVICE_ERROR_SET:
      return {
        ...state,
        services_error: payload,
      }

    case DAY_ADD: {
      const { adminName } = payload

      const source = getLastDay()

      const today = moment().format('YYYY-MM-DD')
      const nextDate = moment(source.date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      const overridedData = {
        pk: 0,
        type: dayTypes.new,
        id: nanoid(),
        date: nextDate,
        payment_date: source.payment_date ? today : '',
        price: state.dayPrices[nextDate] || '',
        admin: adminName,
        discount: 0,
        refund_id: '',
        payed_at: '',
        special_service_id: state.livingService.pk,
      }

      const newDay = {
        ...source,
        ...overridedData,
      }

      const newEnd = moment(state.end)
        .add(1, 'days')
        .format('YYYY-MM-DD')

      return {
        ...state,
        end: newEnd,
        duration: state.duration + 1,
        reserved_days: state.reserved_days.concat(newDay),
      }
    }

    case REFUND_DAY:
      const refundedDay = state.reserved_days.find(d => d.id === payload)

      const refundedSumm = refundedDay.payment_date ? refundedDay.price : 0

      return {
        ...state,
        end: moment(state.end)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        duration: state.duration - 1,
        reserved_days: state.reserved_days.filter(d => d.id !== payload),
        refundSumm: state.refundSumm + refundedSumm,
        refundedItems: state.refundedItems.concat({
          id: refundedDay.id,
          type: 'day',
          price: refundedSumm,
          payment_type: refundedDay.payment_type,
          payment_date: refundedDay.payment_date,
          date: refundedDay.date,
          discount: refundedDay.discount,
          refund_id: refundedDay.refund_id,
          payed_at: refundedDay.payed_at,
          special_service_id: state.livingService.pk,
        }),
      }

    case SET_LATE_CHECKOUT_DATE:
      return {
        ...state,
        additional_services: state.additional_services.map(service => {
          if (service.service !== lateCheckOutService) return service

          return {
            ...service,
            date: payload.newDate,
          }
        }),
      }

    case DAY_SUMM_COPY:
      return {
        ...state,
        reserved_days: [
          ...state.reserved_days.filter(
            d => d.type !== dayTypes.new && d.type !== dayTypes.notPayed
          ),
          ...state.reserved_days
            .filter(
              d => d.type === dayTypes.new || d.type === dayTypes.notPayed
            )
            .map(d => ({
              ...d,
              price: state.reserved_days[0].price,
            })),
        ],
      }

    case DAY_METHOD_COPY:
      return {
        ...state,
        reserved_days: [
          ...state.reserved_days.filter(
            d => d.type !== dayTypes.new && d.type !== dayTypes.notPayed
          ),
          ...state.reserved_days
            .filter(
              d => d.type === dayTypes.new || d.type === dayTypes.notPayed
            )
            .map(d => ({
              ...d,
              payment_type: state.reserved_days[0].payment_type,
            })),
        ],
      }

    case NAME_CHANGED:
      return { ...state, guest_name: payload }

    case PHONE_CHANGED:
      return { ...state, guest_phone: payload }

    case GUESTS_NUMBER_CHANGED:
      return { ...state, guests_number: payload }

    case MAIL_CHANGED:
      return { ...state, guest_mail: payload }

    case BOOKING_NUMBER_CHANGED:
      return {
        ...state,
        booking_number: payload,
        isBookingNumberSelected: false,
      }

    case BOOKING_NUMBER_SELECTED:
      return {
        ...state,
        booking_number: payload,
        isBookingNumberSelected: true,
      }

    case NOTE_CHANGED:
      return { ...state, note: payload }

    case CLIENT_NOTE_CHANGED:
      return { ...state, client_note: payload }

    case WUBOOK_NOTE_CHANGED:
      return { ...state, wubook_note: payload }

    case REFUND_CHANGED:
      return { ...state, has_refund: payload }

    case SHOW_REFUND_MODAL:
      return {
        ...state,
        isRefundModalOpened: true,
      }

    case HIDE_REFUND_MODAL:
      return {
        ...state,
        isRefundModalOpened: false,
      }

    case SHOW_REFUND_ACTION_MODAL:
      return {
        ...state,
        isRefundActionModalOpened: true,
      }

    case HIDE_REFUND_ACTION_MODAL:
      return {
        ...state,
        isRefundActionModalOpened: false,
      }

    case UPGRADE_ERROR_SET:
      return {
        ...state,
        upgrades_error: payload,
      }

    case UPGRADE_ERROR_CLEAR:
      return {
        ...state,
        upgrades_error: '',
      }

    case UPGRADE_DELETE:
      return {
        ...state,
        additional_services: state.additional_services.filter(
          s => s.id !== payload
        ),
        refundSumm:
          state.refundSumm +
          state.additional_services.find(s => s.id === payload).price,
      }

    case RESERVATION_SET: {
      const { reservation, mode = state.mode } = payload

      const newDuration = reservationDuration(
        reservation.start,
        reservation.end
      )
      const { reserved_days: reservedDays } = reservation
      let updatedReservedDays = reservedDays
      updatedReservedDays = reservedDays.map((item) => ({
        ...item,
        id: nanoid(),
        type: item.payment_date ? dayTypes.payed : dayTypes.notPayed,
      })).sort((a, b) => new Date(a.date) - new Date(b.date))
      const rooms = []
      const roomsID = []

      if (reservation.isReady) {
        updatedReservedDays.forEach(day => {
          const { date, room } = day

          if (!room) return

          const { room_id } = room

          if (room_id !== roomsID[roomsID.length - 1]) {
            rooms.push({
              id: room_id,
              date,
            })

            roomsID.push(room_id)
          }
        })
      }

      const updatedAdditionalServices = reservation.additional_services.map(
        service => ({
          id: nanoid(),
          ...service,
          type: serviceTypes.payed,
          service_type: getServiceType(service),
          payed: true,
        })
      )

      const lastRoomID =
        state.pk === reservation.pk
          ? state.lastRoomID
          : roomsID[roomsID.length - 1] || null

      const passport = reservation.passport.length ? reservation.passport.map(item => ({
        ...item,
        id: nanoid(),
      })) : [
        {
          id: nanoid(),
          name: reservation.guest_name,
          passport: '',
        },
      ]

      const newRoomID = state.pk === reservation.pk ? state.newRoomID : null
      const newState = {
        ...state,
        ...reservation,
        room_description: reservation.room_names,
        passport: passport,
        initial_end: reservation.end,
        initial_reserved_days_count: updatedReservedDays.length,
        reserved_days: updatedReservedDays,
        additional_services: updatedAdditionalServices,
        children: Number(reservation.children) || 0,
        duration: newDuration,
        lastRoomID,
        newRoomID,
        rooms,
        isFetching: false,
        mode,
        canceled: reservation.canceled || false,
        is_loyalty_allowed: reservation.is_loyalty_allowed || false,
      }

      newState.initialData = clone(newState)

      return newState
    }

    case AVAILABLE_ROOMS_SET:
      return {
        ...state,
        availableRooms: payload.sort((a, b) => +a.room_id - +b.room_id),
      }

    case CREATE_NEW_RESERVATION:
      const createdDays = []
      let duration = 0
      let cDate = payload.start
      while (cDate !== payload.end) {
        duration += 1

        createdDays.push({
          date: cDate,
          price: '',
          payment_type: '',
          payment_date: moment().format('YYYY-MM-DD'),
          room: {
            room_id: payload.room,
            name: payload.roomType,
          },
          admin: payload.admin,
          id: nanoid(),
          type: dayTypes.new,
          refund_id: '',
          payed_at: '',
          special_service_id: state.livingService.pk,
        })
        cDate = moment(cDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }

      return {
        ...state,
        payed: true,
        start: payload.start,
        end: payload.end,
        reserved_days: createdDays,
        duration,
        rooms: [
          {
            id: payload.room,
            name: payload.roomType,
          },
        ],
        lastRoomID: payload.room,
        admin: payload.adminName,
        isFetching: false,
        lcode: payload.lcode,
        mode: MODES.CREATE,
        booking_number: `${new Date().getTime()}Б`,
      }

    case RESERVATION_CLEAR:
      return initial_state

    case TOGGLE_ENTRY_MODAL:
      return { ...state, isShowingEntryModal: payload }

    case SET_REFUND:
      return { ...state, isRefund: true, isShowingEntryModal: false }

    case SET_SALE:
      return { ...state, isSale: true, isShowingEntryModal: false }

    case types.CHANGE_FIELD:
      return {
        ...state,
        [payload.field]: payload.value,
      }

    case SET_PAYED:
      return {
        ...state,
        payed: payload.value,
      }

    case types.CREATE_DAY:
      return {
        ...state,
        reserved_days: state.reserved_days.concat({
          id: nanoid(),
          pk: 0,
          type: dayTypes.new,
          date: payload.date,
          price: payload.price,
          payment_type: payload.paymentType,
          payment_date: payload.paymentDate,
          discount: 0,
          refund_id: '',
          payed_at: '',
          admin: payload.adminName,
        }),
      }

    case
    types.UPDATE_DAY
    :
      return {
        ...state,
        reserved_days: state.reserved_days.map(day => {
          if (day.id !== payload.id) return day

          return {
            ...day,
            ...payload.updates,
          }
        }),
      }

    case types.DELETE_DAY:
      return {
        ...state,
        reserved_days: state.reserved_days.filter(day => {
          return day.id !== payload.id
        }),
      }

    case types.REFUND_DAY: {
      const refundedDay = state.reserved_days.find(day => {
        return day.id === payload.id
      })

      return {
        ...state,
        reserved_days: state.reserved_days.filter(day => {
          return day.id !== payload.id
        }),
        refundedItems: state.refundedItems.concat({
          id: refundedDay.id,
          type: 'day',
          name: 'Проживание',
          date: refundedDay.date,
          price: refundedDay.price,
          quantity: 1,
          discount: refundedDay.discount,
          payment_type: refundedDay.payment_type,
          payed_at: refundedDay.payed_at,
          refund_id: refundedDay.refund_id,
        }),
      }
    }

    case types.CREATE_SERVICE:
      return {
        ...state,
        additional_services: state.additional_services.concat({
          id: nanoid(),
          uuid: uuid(),
          pk: 0,
          type: serviceTypes.new,
          date: payload.date,
          service_type: payload.serviceType,
          service: payload.name,
          price: Number(payload.price) || 0,
          quantity: Number(payload.quantity) || 1,
          payment_type: payload.paymentType,
          payment_date: null,
          discount: 0,
          refund_id: '',
          payed_at: '',
          admin: payload.adminName,
          special_service_id: payload.special_service_id,
          breakfast_pk: payload.breakfast_pk,
          breakfast_delivery_date: payload.breakfast_delivery_date,
        }),
      }

    case types.UPDATE_SERVICE:
      return {
        ...state,
        additional_services: state.additional_services.map(service => {
          if (service.id !== payload.id) return service

          return {
            ...service,
            ...payload.updates,
          }
        }),
      }

    case types.COPY_SERVICE: {
      const copiedService = state.additional_services.find(service => {
        return service.id === payload.id
      })
      const special_service_id = state.allServices.find(
        it => (it.name === copiedService.service)
      ).pk
      const newService = {
        ...copiedService,
        id: nanoid(),
        uuid: uuid(),
        pk: 0,
        type: serviceTypes.new,
        payment_date: null,
        discount: 0,
        refund_id: '',
        payed_at: '',
        admin: payload.adminName,
        special_service_id,
        ...payload.overrides,
      }

      if (copiedService.service_type === 'breakfast') {
        newService.date = moment().format('YYYY-MM-DD')
      }

      return {
        ...state,
        additional_services: state.additional_services.concat(newService),
      }
    }

    case types.DELETE_SERVICE:
      return {
        ...state,
        additional_services: state.additional_services.filter(service => {
          return service.id !== payload.id
        }),
      }

    case types.REFUND_SERVICE: {
      const refundedService = state.additional_services.find(service => {
        return service.id === payload.id
      })

      const refundedSpecialService = state.allServices.find(service => {
        return service.name === refundedService.service
      })
      const refundedCompletely = payload.quantity === refundedService.quantity

      const alreadyRefundedPartially = state.refundedItems.find(
        item => item.id === refundedService.id
      )

      return {
        ...state,
        additional_services: refundedCompletely
          ? state.additional_services.filter(service => {
            return service.id !== payload.id
          })
          : state.additional_services.map(service => {
            if (service.id !== payload.id) return service

            return {
              ...service,
              quantity: refundedService.quantity - payload.quantity,
            }
          }),
        refundedItems: alreadyRefundedPartially
          ? state.refundedItems.map(item => {
            if (item.id !== refundedService.id) return item

            return {
              ...item,
              quantity: item.quantity + payload.quantity,
            }
          })
          : state.refundedItems.concat({
            id: refundedService.id,
            uuid: refundedService.uuid,
            type: 'service',
            service_type: getServiceType(refundedService),
            name: refundedService.service,
            date: refundedService.date,
            payment_date: refundedService.payment_date,
            price: refundedService.price,
            quantity: payload.quantity,
            discount: refundedService.discount,
            payment_type: refundedService.payment_type,
            payed_at: refundedService.payed_at,
            refund_id: refundedService.refund_id,
            breakfast_pk: refundedService.breakfast_pk,
            breakfast_delivery_date: refundedService.breakfast_delivery_date,
            special_service_id: refundedSpecialService.pk,
          }),
      }
    }

    case GET_BONUSES_FROM_PAID_ITEMS_REQUEST:
      return {
        ...state,
        isGettingBonusesFromPaidItems: true,
      }

    case GET_BONUSES_FROM_PAID_ITEMS_SUCCESS:
    case GET_BONUSES_FROM_PAID_ITEMS_FAILURE:
      return {
        ...state,
        isGettingBonusesFromPaidItems: false,
      }

    case SELECT_MODE:
      return {
        ...state,
        mode: payload,
      }

    case SET_REFUND_CHECK_ID:
      return {
        ...state,
        refundCheckList: payload,
      }

    case types.CARDS_CLEARED_AFTER_RELOCATION:
      return {
        ...state,
        cardsClearedAfterRelocation: true,
      }

    case SET_PREPAYED_BREAKFAST:
      return {
        ...state,
        prePayedBreakfast: payload,
      }

    case SET_SEND_CONFIRMATION_TO_EMAIL:
      return {
        ...state,
        sendConfirmToEmail: true,
      }
    default:
      return state
  }
}

const setPayed = value => ({
  type: SET_PAYED,
  payload: { value },
})

const syncPayed = () => (dispatch, getState) => {
  const state = getState()

  const { reservation } = state
  const { reserved_days, payed } = reservation

  const newPayed = reserved_days.every(day => {
    return Boolean(day.payment_date)
  })

  if (newPayed === payed) {
    return
  }

  dispatch(setPayed(newPayed))
}

const getDayData = day => ({
  price: day.price || 0,
  method: day.payment_type || 'Не выбрано',
  payed: day.payment_date ? 'Оплачено' : 'Не оплачено',
})

export const onDayPriceChange = (id, newPrice) => (dispatch, getState) => {
  dispatch({ type: DAY_PRICE_CHANGED, payload: { id, price: newPrice } })
  const days = getState().reservation.reserved_days
  const day = days.find(d => d.id === id)
  const { price, method, payed } = getDayData(day)

  LogsAPI.editAction(`День ${day.date}`, `${price} | ${method} | ${payed}`)
  dispatch(syncCheckServices())
}

export const onDayMethodChange = (id, newPaymentType) => (
  dispatch,
  getState
) => {
  dispatch({
    type: DAY_METHOD_CHANGED,
    payload: { id, payment_type: newPaymentType },
  })
  const days = getState().reservation.reserved_days
  const day = days.find(d => d.id === id)
  const { price, method, payed } = getDayData(day)
  LogsAPI.editAction(`День ${day.date}`, `${price} | ${method} | ${payed}`)
}

export const onDayPayedChange = (id, newPayed) => (dispatch, getState) => {
  dispatch({ type: DAY_PAYED_CHANGED, payload: { id, payed: newPayed } })
  const days = getState().reservation.reserved_days
  const day = days.find(d => d.id === id)
  const { price, method, payed } = getDayData(day)
  dispatch(syncPayed())
  LogsAPI.editAction(`День ${day.date}`, `${price} | ${method} | ${payed}`)
}

export const onDayRefund = id => async (dispatch, getState) => {
  const state = getState()
  const notBreakfastServices = reservationSelectors.notBreakfastServices(state)
  const breakfastServices = reservationSelectors.breakfastServices(state)

  const { reserved_days, additional_services } = state.reservation
  const day = reserved_days.find(d => d.id === id)

  const notBreakfast = notBreakfastServices.filter(it => it.date === day.date).map(it => it.service)
  const breakfasts = breakfastServices.filter(it => it.breakfast_delivery_date === day.date).map(it => it.service)
  const services = [...notBreakfast, ...breakfasts]

  if (services.length) {
    callAlert({
      title: 'Возврат',
      text: `У возвращаемого дня имеются дополнительные услуги. Пожалуйста, сделайте сначала возврат услуг (${services.join(', ')}).`,
    })
    return
  }

  if (reserved_days.length === 1) {
    const { action } = await callModal(
      modalTemplates.confirm({
        text: [
          'Вы уверены, что хотите выполнить возврат последнего дня брони?',
          'После сохранения бронь без дней будет автоматически удалена.',
        ],
        buttons: [
          'Отмена',
          {
            text: 'Подтвердить',
            color: 'warning',
            action: modalTemplates.confirm.actions.confirm,
          },
        ],
      })
    )

    if (action !== modalTemplates.confirm.actions.confirm) {
      return
    }
  }

  const isWashingOperationsOn = settingsSelectors.isWashingOperationsOn(state)
  const notRefundedServices = additional_services.filter(item => {
    return !(item.service === 'Стирка' && isWashingOperationsOn)
  })

  if (reserved_days.length === 1 && notRefundedServices.length > 0) {
    const modalActions = {
      doNothing: 'do-nothing',
      refundAllServices: 'refund-all-services',
    }

    const { action } = await callModal(
      modalTemplates.confirm({
        width: 800,
        title: 'Выберите действие с услугами',
        text: [
          'У данной брони еще остались услуги.',
          'Если не выполнить их возврат сейчас, они будут удалены вместе с бронью.',
          'Выберите нужное действие:',
        ],
        buttons: [
          'Отмена',
          {
            text: 'Не делать ничего с оставшимися услугами',
            color: 'warning',
            action: modalActions.doNothing,
          },
          {
            text: 'Сделать возврат всех оставшихся услуг',
            color: 'warning',
            action: modalActions.refundAllServices,
          },
        ],
      })
    )

    if (action === modalTemplates.confirm.actions.close) {
      return
    }

    if (action === modalActions.refundAllServices) {
      dispatch(refundAllServices())
    }
  }

  dispatch({ type: REFUND_DAY, payload: id })
  dispatch(syncPayed())
  LogsAPI.pressAction(`Возврат денег за проживание ${day.date}`)

  dispatch(syncCheckServices())
}

export const onReservationPayedChange = isPayed => dispatch => {
  dispatch({ type: RESERVATION_PAYED_CHANGED, payload: isPayed })
  LogsAPI.pressAction(
    `Выбрать все дни оплаченными => ${isPayed ? 'Да' : 'Нет'}`
  )
}

export const loadServices = () => async (dispatch, getState) => {
  try {
    const state = getState()
    await dispatch(hotelsActions.load())
    const common = specialServiceTypesSelectors.common(state)
    const special = specialServiceTypesSelectors.special(state)
    const living = specialServiceTypesSelectors.livingService(state)
    const allServices = specialServiceTypesSelectors.items(state)
    dispatch({
      type: SET_SERVICES,
      payload: {
        common,
        special,
        living,
        allServices
      },
    })
  } catch {
    // skip
  }
}

export const loadAvailableRooms = () => async (dispatch, getState) => {
  const state = getState()

  const start = reservationSelectors.reservationStart(state)
  const end = reservationSelectors.reservationEnd(state)
  const lcode = sessionSelectors.lcode(state)

  try {
    const availableRooms = await getAvailableRoomsCall(
      lcode,
      moment(start).format('YYYY-MM-DD'),
      moment(end).format('YYYY-MM-DD')
    )

    dispatch({ type: AVAILABLE_ROOMS_SET, payload: availableRooms })
  } catch {
    Notifications.failure('Не удалось загрузить список доступных комнат')
  }
}

export const setReservation = ({ reservation, mode }) => ({
  type: RESERVATION_SET,
  payload: {
    reservation,
    mode,
  },
})

export const getReservation = pk => async dispatch => {
  const reservation = await getReservationCall(pk)

  if (reservation.isReady) {
    dispatch(setReservation({ reservation }))
  } else {
    dispatch(setReservation({ reservation, mode: MODES.WUBOOK }))

    dispatch(loadAvailableRooms())
  }

  dispatch(getBreakfast(reservation.booking_number))

  dispatch(updateDayPrices())

  const { booking_number } = reservation

  const isAlpha = AlphaBookingNumberRE.test(booking_number)

  if (!isAlpha) {
    const bookingNumber = reservation.booking_number
    dispatch(prepayedsActions.loadData({ bookingNumber }))
    dispatch(notesActions.loadData({ bookingNumber }))
  }
}
const selectRoomModal = () => {
  callModal(
    modalTemplates.alert({
      title: 'Не выбран номер для заселения',
      text: 'Перед добавлением новых дней выберите номер для заселения',
    })
  )
}
export const onReservationDayAdd = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const hasRoom = reservationSelectors.hasCheckedInRoom(state)

    if (!hasRoom) {
      selectRoomModal()
      return
    }
    const { reservation } = state
    const { date, room } = reservation.reserved_days[reservation.reserved_days.length - 1]

    const isNotAvailable = await isRoomNotAvailable({
      lcode: reservation.lcode,
      reserved_days: [{ date, room }]
    })
    if (isNotAvailable) return

    const adminName = sessionSelectors.adminName(state)

    dispatch({
      type: DAY_ADD,
      payload: {
        adminName,
      },
    })

    LogsAPI.pressAction('Добавить день')

    dispatch(syncCheckServices())
    dispatch(syncPayed())
  }
}

export const onReservationDayRemove = () => dispatch => {
  dispatch({ type: DAY_REMOVE })
  LogsAPI.pressAction('Удалить день')

  dispatch(syncCheckServices())
  dispatch(syncPayed())
}

export const onStartChange = date => async (dispatch, getState) => {
  const state = getState()
  const { start, end, reserved_days, lcode, rooms, availableRooms, isReady } = state.reservation

  const isNotShow = !isReady && moment().isAfter(moment(start))

  if (!rooms.length && availableRooms.length && !isNotShow) {
    selectRoomModal()
    return
  }
  const oldDuration = reservationDuration(start, end)
  const newDuration = reservationDuration(date, end)
  const { room, date: firstDayDate } = reservationSelectors.firstDay(state)

  let newDays = []
  if (newDuration === oldDuration) {
    newDays = reserved_days
  } else if (newDuration < oldDuration) {
    const newStartMoment = moment(date)
    newDays = reserved_days.filter(day => {
      return moment(day.date).isSameOrAfter(newStartMoment)
    })
  } else {
    const firstDayDateMoment = moment(firstDayDate.date)
    for (let offset = 1; offset <= newDuration - oldDuration; offset++) {
      const dateMoment = firstDayDateMoment.clone().subtract(offset, 'days')
      newDays.push({ date: dateMoment.format('YYYY-MM-DD'), room })
    }
    newDays = newDays.concat(reserved_days)
  }

  const noRoom = reserved_days.find(({ room }) => !room)
  if (!noRoom) {
    const isNotAvailable = await isRoomNotAvailable({ lcode, reserved_days: newDays })
    if (isNotAvailable) return
  }

  dispatch({
    type: START_CHANGED,
    payload: {
      date,
      adminName: sessionSelectors.adminName(state),
    },
  })

  LogsAPI.editAction('Начало брони', date)

  dispatch(applyDayPrices())
  dispatch(syncCheckServices())
  dispatch(syncPayed())

  if (!isReady || !availableRooms.length) {
    dispatch(loadAvailableRooms())
  }
}

export const onEndChange = date => async (dispatch, getState) => {
  const state = getState()
  const { start, end, reserved_days, lcode, rooms, availableRooms, isReady } = state.reservation

  const isNotShow = !isReady && moment().isAfter(moment(start))

  if (!rooms.length && availableRooms.length && !isNotShow) {
    selectRoomModal()
    return
  }

  const oldDuration = reservationDuration(start, end)
  const newDuration = reservationDuration(start, date)
  const newEnd = date
  const { room, date: lastDayDate } = reservationSelectors.lastDay(state)

  let newDays = []
  if (newDuration === oldDuration) {
    newDays = reserved_days
  } else if (newDuration < oldDuration) {
    const newEndMoment = moment(newEnd)

    newDays = reserved_days.filter(day => {
      return moment(day.date).isBefore(newEndMoment)
    })
  } else {
    const lastDayDateMoment = moment(lastDayDate)
    for (let offset = 1; offset <= newDuration - oldDuration; offset++) {
      const dateMoment = lastDayDateMoment.clone().add(offset, 'days')
      newDays.push({ date: dateMoment.format('YYYY-MM-DD'), room })
    }
    newDays = newDays.concat(reserved_days)
  }
  const noRoom = reserved_days.find(({ room }) => !room)
  if (!noRoom) {
    const isNotAvailable = await isRoomNotAvailable({ lcode, reserved_days: newDays })
    if (isNotAvailable) return
  }

  dispatch({
    type: END_CHANGED,
    payload: {
      date,
      adminName: sessionSelectors.adminName(state),
    },
  })

  LogsAPI.editAction('Конец брони', date)

  dispatch(applyDayPrices())
  dispatch(syncCheckServices())
  dispatch(syncPayed())

  if (!isReady || !availableRooms.length) {
    dispatch(loadAvailableRooms())
  }
}

export const onDaySummCopy = () => dispatch => {
  dispatch({ type: DAY_SUMM_COPY })
  LogsAPI.pressAction('Копировать цену с первого дня')

  dispatch(syncCheckServices())
}

export const onDayMethodCopy = () => dispatch => {
  dispatch({ type: DAY_METHOD_COPY })
  LogsAPI.pressAction('Копировать метод оплаты с первого дня')
}

export const onNameChange = payload => dispatch => {
  dispatch({ type: NAME_CHANGED, payload })
  LogsAPI.editAction('Имя гостя', payload)
  dispatch(setFirstPassportName(payload))
}

export const onPhoneChange = payload => dispatch => {
  dispatch({ type: PHONE_CHANGED, payload })
  LogsAPI.editAction('Телефон гостя', payload)
}

export const onGuestsNumberChange = payload => async dispatch => {
  dispatch({ type: GUESTS_NUMBER_CHANGED, payload })
  LogsAPI.editAction('Количество взрослых гостей', payload)

  if (payload === '') {
    return
  }

  await dispatch(loadDayPrices())

  const { action } = await callModal(
    modalTemplates.confirm({
      title: 'Внимание',
      text: [
        'В связи с изменением количества гостей, были загружены новые цены на проживание.',
        'Желаете ли автоматически применить новые цены к уже созданным дням?',
      ],
      buttons: ['Закрыть', 'Применить'],
    })
  )

  if (action !== modalTemplates.confirm.actions.confirm) {
    return
  }

  dispatch(applyDayPrices({ forceUpdate: true }))
}

export const onMailChange = payload => async dispatch => {
  await dispatch({ type: MAIL_CHANGED, payload })
  LogsAPI.editAction('Почта гостя', payload)
}

export const onBookingNumberChange = payload => dispatch => {
  if (!/^[а-яёА-ЯЁa-zA-Z0-9. ]*$/.test(payload)) return
  dispatch({ type: BOOKING_NUMBER_CHANGED, payload })
  LogsAPI.editAction('Номер брони', payload)
}

export const onNoteChange = payload => dispatch => {
  dispatch({ type: NOTE_CHANGED, payload })
  LogsAPI.editAction('Примечание', payload)
}

export const onClientNoteChange = payload => dispatch => {
  dispatch({ type: CLIENT_NOTE_CHANGED, payload })
  LogsAPI.editAction('Примечание клиента', payload)
}

export const onWubookNoteChange = payload => dispatch => {
  dispatch({ type: WUBOOK_NOTE_CHANGED, payload })
  LogsAPI.editAction('Примечание Wubook', payload)
}

export const onRefundChange = payload => dispatch => {
  dispatch({ type: REFUND_CHANGED, payload })
  LogsAPI.pressAction(`Бронь невозвратная? => ${payload ? 'Да' : 'Нет'}`)
}

export const showRefundModal = () => ({
  type: SHOW_REFUND_MODAL,
})

export const setBookingNumber = () => ({
  type: SET_BOOKING_NUMBER,
})

export const hideRefundModal = () => ({
  type: HIDE_REFUND_MODAL,
})

export const showRefundActionModal = () => dispatch => {
  LogsAPI.event(
    'Показано модальное окно с предупреждением о создании брони вручную'
  )

  dispatch({
    type: SHOW_REFUND_ACTION_MODAL,
  })
}

export const hideRefundActionModal = () => ({
  type: HIDE_REFUND_ACTION_MODAL,
})

export const onUpgradeDelete = id => (dispatch, getState) => {
  LogsAPI.pressAction('Удалить улучшение')
  const s = getState().reservation.additional_services.find(sr => sr.id === id)
  dispatch({ type: UPGRADE_DELETE, payload: id })
  LogsAPI.event(`Удалено улучшение за ${s.date}`)
}

export const onDontSettleChange = payload => dispatch => {
  dispatch({ type: DONT_SETTLE_CHANGED, payload })
  LogsAPI.pressAction(`Не заселять => ${payload ? 'Да' : 'Нет'}`)
}

export const onCheckInChange = payload => dispatch => {
  if (payload.time.slice(0, 5) === defaultCheckInTime.slice(0, 5)) {
    dispatch(resetCheckIn())
    return
  }

  dispatch({ type: CHECK_IN_CHANGED, payload })
}

export const onCheckOutChange = payload => dispatch => {
  if (payload.time.slice(0, 5) === defaultCheckOutTime.slice(0, 5)) {
    dispatch(resetCheckOut())
    return
  }

  dispatch({ type: CHECK_OUT_CHANGED, payload })
}

export const toggleEntryModal = isShowing => {
  return { type: TOGGLE_ENTRY_MODAL, payload: isShowing }
}

export const onSaleClick = () => {
  return { type: SET_SALE }
}

export const onRefundClick = () => {
  return { type: SET_REFUND }
}

export const onNotPayedDayPriceChange = (id, newPrice) => (
  dispatch,
  getState
) => {
  dispatch({ type: NOT_PAYED_PRICE_CHANGED, payload: { id, price: newPrice } })

  const days = getState().reservation.reserved_days
  const day = days.find(d => d.id === id)
  const { price, method, payed } = getDayData(day)

  LogsAPI.editAction(`День ${day.date}`, `${price} | ${method} | ${payed}`)
  dispatch(syncCheckServices())
}

export const onNotPayedDayMethodChange = (id, newPaymentType) => (
  dispatch,
  getState
) => {
  dispatch({
    type: NOT_PAYED_METHOD_CHANGED,
    payload: { id, payment_type: newPaymentType },
  })
  const days = getState().reservation.reserved_days
  const day = days.find(d => d.id === id)
  const { price, method, payed } = getDayData(day)
  LogsAPI.editAction(`День ${day.date}`, `${price} | ${method} | ${payed}`)
}

export const onNotPayedDayPayedChange = (id, newPayed) => (
  dispatch,
  getState
) => {
  dispatch({ type: NOT_PAYED_PAYED_CHANGED, payload: { id, payed: newPayed } })
  const days = getState().reservation.reserved_days
  const day = days.find(d => d.id === id)
  const { price, method, payed } = getDayData(day)
  dispatch(syncPayed())
  LogsAPI.editAction(`День ${day.date}`, `${price} | ${method} | ${payed}`)
}

export const closePaymentMethodErrorsModal = () => ({
  type: CLOSE_PAYMENT_METHOD_ERROR,
})

export const closeValidationErrorsModal = () => ({
  type: CLOSE_VALIDATION_ERRORS_MODAL,
})

export const hideSuccessModal = () => ({
  type: SUCCESS_MODAL_HID,
})

export const hideChangeDaysCountModal = () => ({
  type: HIDE_CHANGE_DAYS_COUNT_MODAL,
})

export const hideErrorModal = () => ({
  type: ERROR_MODAL_HID,
})

export const showPrintServerError = () => ({
  type: SHOW_PRINT_SERVER_ERROR,
})

export const hidePrintServerError = () => ({
  type: HIDE_PRINT_SERVER_ERROR,
})

export const confirmRefund = () => ({
  type: CONFIRM_REFUND,
})

export const clearReservation = () => dispatch => {
  dispatch({ type: RESERVATION_CLEAR })
  dispatch(clearTempLogs())
  dispatch(loyaltyClientActions.reset())
  dispatch(loyaltyPurchaseActions.reset())
}

export const createNewReservation = (room, roomType, start, end) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { admin, lcodeObj } = sessionSelectors.everything(state)
  const payload = {
    room,
    roomType,
    start,
    end,
    admin,
    lcode: lcodeObj
  }

  dispatch({ type: CREATE_NEW_RESERVATION, payload })
  dispatch(updateDayPrices())
}

export const doMigration = (date, room) => async (dispatch, getState) => {
  dispatch({ type: LOADING_SHOW })
  const state = getState()

  const goAhead = await statusWarningAlert(room.status)
  if (!goAhead) {
    dispatch({ type: LOADING_HID })
    return
  }

  const days = reservationSelectors.days(state)
  const lcode = reservationSelectors.lcode(state)
  const firstMigrationDateObj = moment(date)

  const getDaysAfterMigration = array =>
    array.reduce((a, b) => {
      if (moment(b.date).isSameOrAfter(firstMigrationDateObj))
        a.push({ room, date: b.date })
      return a
    }, [])
  const reserved_days = getDaysAfterMigration(days)
  const isNotAvailable = await isRoomNotAvailable({ lcode, reserved_days })
  if (isNotAvailable) {
    dispatch({ type: LOADING_HID })
    return
  }

  const cards = reservationSelectors.cards(state)

  const day = days.find(day => day.date === date)

  const logText =
    `Выполнено переселение в комнату ${room.room_id} ` +
    `из комнаты ${day.room.room_id} (с ${dayFormatter(date)})`

  LogsAPI.event(logText)

  if (cards > 0) {
    await dispatch({ type: RESET_CARDS })
  }

  dispatch({
    type: MIGRATION_DONE,
    payload: {
      date,
      room,
    },
  })
}

export const setRooms = room => async (dispatch, getState) => {
  let state = getState()
  const { reservation } = state
  const { room_names, lcode } = reservation

  const goAhead = await statusWarningAlert(room.status)
  if(!goAhead) return

  if (!room_names.includes(room.name)) {

    const reserved_days = reservationSelectors.daysDates(state).reduce((a, b) => {
      a.push({ room, date: b })
      return a
    }, [])
    const isNotAvailable = await isRoomNotAvailable({
      lcode,
      reserved_days
    })
    if (isNotAvailable) return
  }
  dispatch({
    type: SET_ROOMS,
    payload: room,
  })

  await dispatch(updateDayPrices())

  state = getState()
  const { check_out_time, check_in_time } = state.reservation
  const checkIn = reservationSelectors.checkInService(state)
  if (checkIn) dispatch(changeCheckInTime(check_in_time))

  const checkOut = reservationSelectors.checkOutService(state)
  if (checkOut) dispatch(changeCheckOutTime(check_out_time))
}

const oldWriteCardCalls = async ({
                                   cardsAmount,
                                   roomId,
                                   hsuRoomId,
                                   rooms,
                                   check_in_date,
                                   check_in_time,
                                   check_out_date,
                                   check_out_time,
                                   isLockEnabled,
                                   guestName,
                                   apiData,
                                   doorLockType
                                 }) => {
  let room = roomId
  if (doorLockType === doorLocksTypes.HSU) {
    room = hsuRoomId || room
  }
  if (isLockEnabled) {
    if (cardsAmount > 0) {
      return cardAPI[doorLockType].copycardCall({
        room,
        check_in_date,
        check_in_time,
        check_out_date,
        check_out_time,
        guestName,
        ...apiData
      })
    }
    if (doorLockType === doorLocksTypes.v1) {
      const checkedOut = new Set()
      for (const room of rooms) {
        if (checkedOut.has(room.id)) continue
        await cardAPI[doorLockType].checkoutCall({ room: room.id, ...apiData })
        checkedOut.add(room.id)
      }
    }
    return await cardAPI[doorLockType].checkinCall({
      room,
      check_in_date,
      check_in_time,
      check_out_date,
      check_out_time,
      isLockEnabled,
      guestName,
      ...apiData
    })
  } else {
    if (cardsAmount > 0) {
      return { isSuccess: true, method: 'copycard', data: { room } }
    }
    return { isSuccess: true, method: 'checkin', data: { room } }
  }
}

export const writeCard = () => async (dispatch, getState) => {
  const state = getState()

  const { reservation } = state

  const {
    hasCard,
    reserved_days: days,
    rooms,
    lastRoomID,
    newRoomID,
    start,
    check_in_time: checkInTime,
    check_out_time: checkOutTime,
    guest_name: guestName
  } = reservation
  const cardsAmount = Number(hasCard)

  const isLoyaltyUsed = loyaltySelectors.isUsed(state)
  const hasEarlyCheckIn = reservationSelectors.hasEarlyCheckIn(state)

  if (days.every(day => !day.payment_date)) {
    dispatch({
      type: SET_WRITECARD_ERROR,
      payload: 'Бронь не оплачена, карта не может быть записана',
    })

    return { isSuccess: false }
  }

  const wasRelocation = rooms.length >= 2

  let defaultRoom = newRoomID
  if (!newRoomID) {
    const newDays = days.find(it => it.type === 'new')
    defaultRoom = newDays ? newDays.room.room_id : lastRoomID
  }

  const updateCards = async () => {
    dispatch({ type: INCREASE_CARDS })
    const { hasCard, pk } = reservationSelectors.everything(getState())
    await updateCardsCall({ hasCard, pk })

    if (hasCard === 1 && isLoyaltyUsed) {
      await dispatch(getBonusesFromPaidItems({ silent: true }))
    }
  }
  const doorLockType = settingsSelectors.isDoorLocks(state)
  const apiData = settingsSelectors.apiData(state)
  const { date: dateFormat, time: timeFormat } = locksDateTimeFormat[doorLockType]

  const getCheckPeriod = () => {
    const today = moment()
    const cardDate = today.subtract(1, 'hour')

    const [check_in_date, check_in_time] = wasRelocation
      ? [cardDate.format(dateFormat), cardDate.format(timeFormat)]
      : [
        moment(start, 'YYYY-MM-DD').format(dateFormat),
        hasEarlyCheckIn
          ? moment(checkInTime, "HH:mm:ss").format(timeFormat)
          : defaultLockCheckinTime[doorLockType]
      ]

    let lastPaidDate = null
    for (const day of days) {
      if (!day.payment_date) continue
      lastPaidDate = day.date
    }
    let checkOutDateTime
    const checkOutPrice = reservationSelectors.checkOutPrice(state)

    if (checkOutPrice && checkOutTime !== defaultCardCheckOutTime) {
      const { hours, minutes, seconds } = reservationSelectors.checkOutTimeObject(state)

      const nextLastPaidDate = moment(lastPaidDate).add(1, 'day')

      const latestDateTime = moment(nextLastPaidDate).format('YYYY-MM-DD 12:00:00')

      checkOutDateTime = moment(nextLastPaidDate).set({
        hour: hours,
        minute: minutes,
        second: seconds
      })
        .add(20, 'minutes')

      if (moment(latestDateTime).isAfter(checkOutDateTime)) {
        checkOutDateTime = checkOutDateTime.add(1, 'day')
      }
    } else {
      checkOutDateTime = moment(lastPaidDate)
        .add(1, 'day')
    }

    const check_out_date = moment(checkOutDateTime).format(dateFormat)

    const check_out_time =
      checkOutTime
        ? moment(checkOutTime, 'HH:mm:ss')
          .add(20, 'minutes')
          .format(timeFormat)
        : defaultLockCheckOutTime[doorLockType]
    return {
      check_in_date,
      check_in_time,
      check_out_date,
      check_out_time,
    }
  }

  try {
    const {
      check_in_date,
      check_in_time,
      check_out_date,
      check_out_time,
    } = getCheckPeriod()

    const state = getState()
    const isLockEnabled = settingsSelectors.isLockEnabled(state)
    const roomsList = roomsSelectors.mapRoomIdToRoom(state)
    const roomId = newRoomID || defaultRoom

    const result = await oldWriteCardCalls({
      cardsAmount,
      roomId,
      hsuRoomId: roomsList[roomId].transformed_room_id,
      rooms,
      check_in_date,
      check_in_time,
      check_out_date,
      check_out_time,
      isLockEnabled,
      guestName,
      apiData,
      doorLockType
    })
    if (result.error) {
      throw new Error(result.error)
    }

    await updateCards()

    return { isSuccess: true, meta: `${result.method}: ${result.data?.room}` }
  } catch (error) {
    dispatch({
      type: SET_WRITECARD_ERROR,
      payload: error?.message ?? 'Неизвестная ошибка',
    })

    return { isSuccess: false, error: error?.message ?? 'Неизвестная ошибка' }
  }
}

export const clearWritecardError = () => ({
  type: CLEAR_WRITECARD_ERROR,
})

export const confirmDuplicate = () => ({
  type: CONFIRM_DUPLICATE,
})

export const clearDuplicates = () => ({
  type: CLEAR_DUPLICATES,
})

export const resetCards = () => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_SHOW })
  dispatch({ type: RESET_CARDS })
  const { hasCard, pk } = reservationSelectors.everything(getState())
  await updateCardsCall({ hasCard, pk })

  callAlert({
    title: 'Успех',
    text: 'Карты были сброшены',
  })
  dispatch({ type: LOADING_HID })
}

export const loadDayPrices = () => async (dispatch, getState) => {
  const state = getState()
  const { lastRoomID, guests_number, booked_room_occupancy = 0, pk } = state.reservation
  const { lcode } = state.session
  const { isWubookUpdatingModifiedPricesEnabled } = state.settings

  if (!lastRoomID || !guests_number) {
    return
  }
  const guests = pk ? guests_number > booked_room_occupancy ? booked_room_occupancy : guests_number : guests_number

  const dirtyDayPrices = isWubookUpdatingModifiedPricesEnabled
    ? await getWubookModifiedPricesCall(lcode, lastRoomID, guests)
    : await getWubookPricesCall(lcode, lastRoomID, guests)

  const dayPrices = dirtyDayPrices.reduce((acc, { date, price }) => {
    acc[date] = Number(price)
    return acc
  }, {})

  dispatch({
    type: SET_DAY_PRICES,
    payload: {
      dayPrices,
    },
  })
}

export const applyDayPrices = ({ forceUpdate = true } = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()

  const { reserved_days, dayPrices } = state.reservation

  const updateDay = day => {
    if (day.type !== dayTypes.new) {
      return day
    }

    const predefinedPrice = dayPrices[day.date]

    if (forceUpdate) {
      return {
        ...day,
        price: predefinedPrice || '',
      }
    }

    if (day.price) {
      return day
    }

    if (!predefinedPrice) {
      return day
    }

    return {
      ...day,
      price: predefinedPrice,
    }
  }

  const nextReservedDays = reserved_days.map(updateDay)

  dispatch({
    type: APPLY_DAY_PRICES,
    payload: {
      nextReservedDays,
    },
  })
}

export const updateDayPrices = () => async dispatch => {
  await dispatch(loadDayPrices())
  dispatch(applyDayPrices())
}

export const getBreakfast = booking_number => async dispatch => {
  await ccAPI
    .getBreakfastActions({ booking_number: booking_number })
    .then(res => {
      dispatch({
        type: SET_PREPAYED_BREAKFAST,
        payload: res.filter(({ is_prepayed }) => is_prepayed),
      })
    })
}

const setLoading = value => ({
  type: SET_LOADING,
  payload: {
    value,
  },
})

export const printCheckAgain = () => async dispatch => {
  dispatch(setLoading(true))

  const res = await CheckPrintController.printCheckAgain()

  dispatch(setLoading(false))
  if (res.Status === 0) {
    Notifications.success('Команда успешно выполнена')
  } else {
    callModal(
      modalTemplates.alert({
        title: 'Ошибка выполнения команды',
        text: res.Error || 'Неизвестная ошибка',
      })
    )
  }
}

export const onPassportCreate = () => (dispatch, getState) => {
  LogsAPI.pressAction('Добавить паспорт')

  const state = getState()
  const { guest_name, passport } = state.reservation

  const name = passport.length > 0 ? '' : guest_name

  dispatch({
    type: PASSPORT_CREATE,
    payload: {
      name,
    },
  })
}

const passportKeyToName = {
  name: 'Имя',
  passport: 'Номер',
}

const getPassportEditTarget = (idx, key) => {
  return `${passportKeyToName[key]} паспорта №${idx + 1}`
}

export const onPassportEdit = (id, key, value) => (dispatch, getState) => {
  const state = getState()
  const { passport } = state.reservation

  const idx = passport.findIndex(item => item.id === id)

  LogsAPI.editAction(getPassportEditTarget(idx, key), value)

  dispatch({
    type: PASSPORT_EDIT,
    payload: {
      id,
      key,
      value,
    },
  })
}

export const setFirstPassportName = value => (dispatch, getState) => {
  const state = getState()
  const { passport } = state.reservation

  if (passport.length === 0) return
  const firstPassportId = passport[0].id

  dispatch(onPassportEdit(firstPassportId, 'name', value))
}

export const onPassportRemove = id => (dispatch, getState) => {
  const state = getState()
  const { passport } = state.reservation

  const idx = passport.findIndex(item => item.id === id)

  LogsAPI.pressAction(`Удалить паспорт №${idx}`)

  dispatch({
    type: PASSPORT_REMOVE,
    payload: {
      id,
    },
  })
}

export const logCustom = text => (_, getState) => {
  const { pk, created_at } = getState().reservation
  if (created_at) Logs.logSubmit(pk, text)
  else Logs.log(pk, text)
}

export const logPressAction = target => (_, getState) => {
  const { pk, created_at } = getState().reservation
  if (created_at) Logs.logPressActionSubmit(pk, target)
  else Logs.logPressAction(pk, target)
}

export const logEditAction = (value, target) => (_, getState) => {
  const { pk, created_at } = getState().reservation
  if (created_at) Logs.logEditActionSubmit(pk, value, target)
  else Logs.logEditAction(pk, value, target)
}

export const logEvent = event => (_, getState) => {
  const { pk, created_at } = getState().reservation
  if (created_at) Logs.logEventSubmit(pk, event)
  else Logs.logEvent(pk, event)
}

export const logError = error => (_, getState) => {
  const { pk, created_at } = getState().reservation
  if (created_at) Logs.logErrorSubmit(pk, error)
  else Logs.logError(pk, error)
}

export const submitLogs = () => () => {
  Logs.submit()
}

export const submitTempLogs = pk => () => {
  Logs.submitTemp(pk)
}

export const clearLogs = () => () => {
  Logs.clear()
}

export const clearTempLogs = () => () => {
  Logs.clearTemp()
}

export const resetCheckIn = () => ({
  type: RESET_CHECK_IN,
})

export const resetCheckOut = () => ({
  type: RESET_CHECK_OUT,
})

export const addPassportsToBlacklist = () => async (dispatch, getState) => {
  const { reservation, session } = getState()

  const { passport: passports, booking_number, lcode } = reservation

  if (passports.length < 1) {
    Notifications.failure('Не добавлено ни одного паспорта')
    return
  }

  if (passports.some(({ name, passport }) => !name || !passport)) {
    Notifications.failure(
      'У одного или нескольких паспортов не указано имя или номер'
    )
    return
  }

  const normalizedPassports = passports.map(item => ({
    ...item,
    passport_number: item.passport.replace(/ /g, ''),
  }))

  const confirmationRes = await callModal(
    modalTemplates.confirm({
      text: 'Вы уверены что хотите внести данные паспорта в чёрный список?',
    })
  )

  if (confirmationRes.action !== modalTemplates.confirm.actions.confirm) {
    return
  }

  const reasonRes = await callModal(
    modalTemplates.prompt({
      title: 'Дополнительные данные',
      fieldName: 'Причина блокировки',
      fieldPlaceholder: 'Введите причину блокировки',
    })
  )

  if (reasonRes.action !== modalTemplates.prompt.actions.enter) {
    return
  }

  const { value: reason } = reasonRes.params

  const promises = normalizedPassports.map(({ name, passport_number }) => {
    return ccAPI.addPassportToBlacklist({
      name,
      passport_number,
      reason,
      booking_number,
      lcode,
      admin: { uid: session.adminID }
    })
  })

  try {
    await Promise.all(promises)

    Notifications.success('Паспорта успешно добавлены в чёрный список')
  } catch (err) {
    Notifications.failure(
      'При добавлении паспортов в чёрный список возникла ошибка'
    )
  }
}

export const deleteReservation = () => async (dispatch, getState) => {
  const state = getState()

  const reservationPk = reservationSelectors.pk(state)
  const prePayedBreakfast = reservationSelectors.prePayedBreakfast(state)

    const prePayedBreakfastDates = reservationSelectors.initialData(state).additional_services
        .filter(({service_type}) => service_type === 'breakfast')
        .map(({breakfast_delivery_date}) => breakfast_delivery_date)

  let breakfast_delivery_dates = []
  if (prePayedBreakfast.length)
    breakfast_delivery_dates = await getDeletePrepayedBreakfastList({ prePayedBreakfast })

    breakfast_delivery_dates = [...new Set([...breakfast_delivery_dates, ...prePayedBreakfastDates])]

  async function tryToRefundBonuses() {
    try {
      const discountedItems = reservationSelectors.discountedItems(state)
      const phoneNumber = reservationSelectors.phoneNumber(state)
      const settings = settingsSelectors.everything(state)
      const session = sessionSelectors.everything(state)
      const isWashingOperationsOn = settingsSelectors.isWashingOperationsOn(
        state
      )

      const cancelResult = await cancelBonusesForItems({
        days: discountedItems.days,
        services: discountedItems.services.filter(item => {
          return !(item.service === 'Стирка' && isWashingOperationsOn);
        }),
        phoneNumber,
        settings,
        session
      })

      if (!cancelResult.isSuccess) {
        throw new LoyaltyError(cancelResult.errorCode)
      }

      LogsAPI.event('Возвращены бонусы за удаленные дни и услуги')

      return { wasSuccessful: true }
    } catch (error) {
      const message = error?.message ?? 'Неизвестная ошибка'

      LogsAPI.custom(`Не удалось возвратить бонусы: ${message}`)

      const modalActions = {
        tryAgain: 'try-again',
        skip: 'skip',
      }

      const { action } = await callModal(
        modalTemplates.confirm({
          title: 'Ошибка при возврате бонусов',
          text: message,
          buttons: [
            'Отмена',
            {
              text: 'Продолжить без возврата бонусов',
              color: 'warning',
              action: modalActions.skip,
            },
            {
              text: 'Попробовать еще раз',
              color: 'success',
              action: modalActions.tryAgain,
            },
          ],
          width: 800,
        })
      )

      if (action === modalTemplates.confirm.actions.close) {
        return { wasSuccessful: false, wasCanceled: true }
      }

      if (action === modalActions.tryAgain) {
        return tryToRefundBonuses()
      }

      if (action === modalActions.skip) {
        return { wasSuccessful: true }
      }

      return { wasSuccessful: false }
    }
  }

  const refundBonusesRes = await tryToRefundBonuses()

  if (refundBonusesRes.wasCanceled) {
    return { wasDeleted: false, wasCanceled: true }
  }

  if (!refundBonusesRes.wasSuccessful) {
    return { wasDeleted: false }
  }

  async function tryToDeleteReservation() {
    try {
      await deleteReservationCall({ pk: reservationPk, data: { breakfast_delivery_dates } })

      LogsAPI.event('Бронь была успешно удалена')
      window.localStorage.setItem("reservation_changed", +new Date());

      return { wasDeleted: true }
    } catch (error) {
      const message = error?.message?.slice(0, 100) ?? 'Неизвестная ошибка'

      LogsAPI.custom(`Не удалось удалить бронь: ${message}`)

      const { action } = await callModal(
        modalTemplates.confirm({
          title: 'Не удалось удалить бронь',
          text: message,
          buttons: ['Отмена', 'Попробовать еще раз'],
        })
      )

      if (action === modalTemplates.confirm.actions.close) {
        return { wasDeleted: false, wasCanceled: false }
      }
      return tryToDeleteReservation()
    }
  }
  return tryToDeleteReservation()
}

const getBonusesFromPaidItemsRequest = () => ({
  type: GET_BONUSES_FROM_PAID_ITEMS_REQUEST,
})

const getBonusesFromPaidItemsSuccess = () => ({
  type: GET_BONUSES_FROM_PAID_ITEMS_SUCCESS,
})

const getBonusesFromPaidItemsFailure = () => ({
  type: GET_BONUSES_FROM_PAID_ITEMS_FAILURE,
})

export const getBonusesFromPaidItems = ({ silent = false } = {}) => async (
  dispatch,
  getState
) => {
  dispatch(getBonusesFromPaidItemsRequest())

  const state = getState()

  const result = await applyBonusesForPaidItems({ state, applyBonuses: 0 })

  if (!result.isSuccess) {
    dispatch(getBonusesFromPaidItemsFailure())

    const { error } = result

    if (!silent) {
      Notifications.failure(error)
    }

    return
  }

  const { savedReservation, collectedBonuses } = result

  dispatch(setReservation({ reservation: savedReservation }))
  dispatch(getBonusesFromPaidItemsSuccess())

  if (!silent) {
    Notifications.success(`Успех, начислено ${collectedBonuses} бонусов`)
  }
}

export const selectMode = mode => ({
  type: SELECT_MODE,
  payload: mode,
})

export const getChecks = () => async (dispatch, getState) => {
  const state = getState()
  await dispatch(checksActions.getCheckByUUID(state.reservation.pk))
}

export const setChecks = () => async (dispatch, getState) => {
  const state = getState()
  const check = await checksSelectors.checksSelector(state)
  await dispatch({
    type: SET_REFUND_CHECK_ID,
    payload: check.itemsCC,
  })
}
const validatePassport = reservation => isPassportValid(reservation.passport)
//
// if (isValid) return true
//
// const isNoRefund = reservation.has_refund
//
// if (isNoRefund) return true
// console.log('reservation', reservation)
// const isPayedByBank =
//   reservation.reserved_days[0].payment_type === paymentTypes.bank
// const isStartToday = reservation.start === moment().format('YYYY-MM-DD')
//
// if (isPayedByBank) {
//   return !isStartToday
// }
//
// return false

export const checkPaymentTypes = () => async (dispatch, getState) => {
  dispatch({ type: CLEAR_PAYMENT_METHOD_ERROR })

  const state = getState()
  const res = reservationSelectors.hasNoDistinctPaymentType(state)
  if (res)
    dispatch({ type: SET_PAYMENT_METHOD_ERROR })
}

export const validate = () => async (dispatch, getState) => {
  dispatch({ type: CLEAR_ERROR_FIELDS })

  const state = getState()
  const reservation = reservationSelectors.everything(state)
  const modes = reservationSelectors.modes(state)

  const generalErrors = {}
  const daysErrors = {}
  const servicesErrors = {}

  const addGeneralError = field => {
    generalErrors[field] = true
  }

  const addError = (object, field, error) => {
    if (!object[field]) object[field] = {}
    object[field][error] = true
  }

  const addDayError = (id, error) => addError(daysErrors, id, error)
  const addServiceError = (id, error) => addError(servicesErrors, id, error)

  const createDayErrorGroup = id => {
    daysErrors[id] = {}
  }

  const createServiceErrorGroup = id => {
    servicesErrors[id] = {}
  }

  const generalRequiredFields = [
    'guest_name',
    'booking_number',
    'guest_phone',
    'guest_mail',
  ]

  for (let field of generalRequiredFields) {
    if (!reservation[field]) {
      addGeneralError(field)
    }
  }

  if (reservation.guests_number < 1) {
    addGeneralError('guests_number')
  }

  if (
    reservation.guest_mail &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(reservation.guest_mail)
  ) {
    addGeneralError('guest_mail')
  }

  if (!validatePassport(reservation)) {
    addGeneralError('passport')
  }

  if (
    !reservation.newRoomID &&
    !modes.create &&
    !reservation.isReady &&
    !reservation.dontSettle
  ) {
    addGeneralError('rooms')
  }

  if (reservation.reserved_days.length > 120) {
    addGeneralError('days-count')
  }

  const dayRequiredFields = ['date', 'price']

  for (let day of reservation.reserved_days) {
    createDayErrorGroup(day.id)

    for (let field of dayRequiredFields) {
      if (!day[field]) addDayError(day.id, field)
    }

    if (!day.payment_type && day.payment_date) {
      addDayError(day.id, 'payment_type')
    }
  }

  const serviceRequiredFields = [
    'date',
    'service',
    'price',
    'quantity',
    'payment_type',
  ]

  for (let service of reservation.additional_services) {
    createServiceErrorGroup(service.id)

    for (let field of serviceRequiredFields) {
      if (!service[field]) addServiceError(service.id, field)
    }

    if (service.service_type === 'breakfast') {
      if (!service.breakfast_delivery_date && service.type === 'new')
        addServiceError(service.id, 'breakfast_delivery_date')
    }
  }

  const isWashingOperationsOn = settingsSelectors.isWashingOperationsOn(state)

  if (isWashingOperationsOn) {
    const newWashingServices = reservationSelectors.newWashingServices(state)
    const newWashingServicesIds = newWashingServices.map(service => service.id)
    const newWashingsCount = reservationSelectors.newWashingsCount(state)
    const numberOfFreeMachines = washingSelectors.numberOfFreeMachines(state)

    if (newWashingsCount > numberOfFreeMachines) {
      addGeneralError('too-many-washing-services')

      for (const id of newWashingServicesIds) {
        addServiceError(id, 'quantity')
      }
    }
  }

  const servicesAndUpgrades = reservation.additional_services.filter(
    ({ service_type }) =>
      service_type !== 'checkin' && service_type !== 'checkout',
  )

  for (let i = 0; i < servicesAndUpgrades.length - 1; i++) {
    for (let j = i + 1; j < servicesAndUpgrades.length; j++) {
      const first = servicesAndUpgrades[i]
      const second = servicesAndUpgrades[j]

      if (
        first.date === second.date &&
        first.payment_type !== second.payment_type
      ) {
        addServiceError(first.id, 'payment_type')
        addServiceError(second.id, 'payment_type')
        addGeneralError('same-day-payment_type')
      }
    }
  }

  const errors = {
    general: generalErrors,
    days: daysErrors,
    services: servicesErrors,
  }

  let count = 0

  for (let groupName in errors) {
    const group = errors[groupName]

    for (let itemName in group) {
      const item = group[itemName]

      if (typeof item === 'object') {
        count += Object.keys(item).length
      } else {
        count += 1
      }
    }
  }

  if (count > 0) {
    dispatch({ type: SET_ERROR_FIELDS, payload: errors })
  }
}

export const emailConfirmation = (reservation) => async (dispatch) => {
  const actionEmail = await callModal(
    modalTemplates.confirm({
      text:
        'Спросите гостя, нужен ли ему печатный чек или достаточно электронного на почту',
      buttons: ['Нужен печатный', 'Нужен электронный'],
    })
  )
  if (actionEmail.action === modalTemplates.confirm.actions.confirm) {
    const payload = await callPrompt({
      title: 'Проверка',
      text: [
        'Подтвердите имейл, на который придет электронный чек. Если он с ошибкой, исправьте',
      ],
      initialValue: reservation.guest_mail,
    })
    if (payload) {
      if (payload !== reservation.guest_mail) {
        await dispatch({ type: MAIL_CHANGED, payload })
      }
      await dispatch({ type: SET_SEND_CONFIRMATION_TO_EMAIL })
    }
  }
}

export const getRegistrationForms = () => async (dispatch) => {
  await dispatch(registrationFormsActions.loadForms())
}

export const getLateCheckOutList = date => async (dispatch) => {
  dispatch(lateCheckoutsActions.loadData(date))
}

export const getValidation = (reservation) => async () => {
  return await ccAPI.validateReservation(reservation)
    .then(_ => true)
    .catch(async error => {
      const { code, details_pretty_ru, meta } = error
      if (+code === 100) {
        callAlert({
          title: 'Ошибка',
          text: [details_pretty_ru, `Номер брони: ${meta[0]}`, `Гость: ${meta[1]}`, `Идентификатор: ${meta[2]}`],
        })
      }
      return false
    })
}
