import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ByType } from './components/ByType'
import { Segments } from './components/common'
import { ForDate } from './components/ForDate'

const MainBlock = styled.div`
  padding: 30px;
`

const MainTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
`

export const FreeRooms = ({
  byType,
  calculatedByTypeData,
  forDate,
  freeRoomsActions,
  roomsPhysicalActions,
  roomsPhysical
}) => {
  useEffect(() => {
    freeRoomsActions.loadByTypeData()
    roomsPhysicalActions.load()
  }, [freeRoomsActions, roomsPhysicalActions])

  return (
    <MainBlock>
      <MainTitle>Свободные комнаты</MainTitle>
      <Segments>
        <ByType
          isLoading={byType.isLoading}
          isLoaded={byType.isLoaded}
          isFailed={byType.isFailed}
          calculatedData={calculatedByTypeData}
          load={freeRoomsActions.loadByTypeData}
          refresh={freeRoomsActions.loadByTypeData}
        />
        <ForDate
          isLoading={forDate.isLoading}
          isLoaded={forDate.isLoaded}
          isFailed={forDate.isFailed}
          date={forDate.date}
          count={forDate.count}
          select={freeRoomsActions.loadForDateData}
        />
      </Segments>
    </MainBlock>
  )
}
