import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TodayCheckIns from './TodayCheckIns'
import {
  todayCheckInsActions,
  todayCheckInsSelectors,
} from '../../reducers/today-check-ins'
import { allNotesSelectors } from '../../../../Modules/all-notes'

const mapStateToProps = state => ({
  wubook: todayCheckInsSelectors.wubook(state),
  count: todayCheckInsSelectors.count(state),
  cancelingPKs: todayCheckInsSelectors.cancelingPKs(state),
  processedPrepayeds: todayCheckInsSelectors.processedPrepayeds(state),
  allNotesByBN: allNotesSelectors.byBookingNumber(state),
  isUpdating: todayCheckInsSelectors.isUpdating(state),
  loadState: todayCheckInsSelectors.loadState(state),
})

const mapDispatchToProps = dispatch => ({
  todayCheckInsActions: bindActionCreators(todayCheckInsActions, dispatch),
})

export const ConnectedTodayCheckIns = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodayCheckIns)
