import React from 'react'
import styled from 'styled-components'
import { Tasks } from './components/tasks'
import { ConnectedTodayCheckIns } from './components/today-check-ins'
import { ConnectedFreeRooms } from './components/free-rooms'
import { ConnectedTodayNoShow } from './components/today-no-show'
import { ReservationsWaitingForBonuses } from './components/reservations-waiting-for-bonuses'
import { useSelector } from 'react-redux'
import { settingsSelectors } from '../../Modules/settings'
import { FreeWashingMachines } from './components/free-washing-machines'
import { BreakfastsToday } from './components/breakfasts-today'
import { MaidsCheckOutMain } from './components/MaidsCheckOutMain'
import { BreakfastWarnings, ReservationWarnings } from './components/canceled-warnings'

const LeftSideBlock = styled.div`
  width: 500px;
  flex-shrink: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #dde2ec;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;
  }
`

const LeftSide = () => {
  const isLoyaltyEnabled = useSelector(settingsSelectors.isLoyaltyEnabled)
  const isTodoListEnabled = useSelector(settingsSelectors.isTodoListEnabled)
  const isWashingOperationsOn = useSelector(
    settingsSelectors.isWashingOperationsOn
  )

  return (
    <LeftSideBlock>
      <MaidsCheckOutMain />
      <ReservationWarnings/>
      <BreakfastWarnings/>
      <BreakfastsToday />
      {isLoyaltyEnabled && <ReservationsWaitingForBonuses />}
      {isTodoListEnabled && <Tasks />}
      {isWashingOperationsOn && <FreeWashingMachines />}
      <ConnectedFreeRooms />
      <ConnectedTodayCheckIns />
      <ConnectedTodayNoShow />
    </LeftSideBlock>
  )
}

export default LeftSide
