export const LOAD_CANCELED_WARNINGS_REQUEST =
  'ghotel/canceled-warnings/LOAD_CANCELED_WARNINGS_REQUEST'
export const LOAD_CANCELED_WARNINGS_SUCCESS =
  'ghotel/canceled-warnings/LOAD_CANCELED_WARNINGS_SUCCESS'
export const LOAD_CANCELED_WARNINGS_FAILURE =
  'ghotel/canceled-warnings/LOAD_CANCELED_WARNINGS_FAILURE'
export const UPDATE_ITEMS_LIST =
  'ghotel/canceled-warnings/UPDATE_ITEMS_LIST'


export const RESET = 'ghotel/free-rooms/RESET'
